import React from "react";
// import { Table, Button } from 'reactstrap'
// import Timeline from 'react-visjs-timeline'
import { ContextMenu, MenuItem, SubMenu } from "react-contextmenu"

const visContextMenu = props => {
  let propTitle
  let clipType
  let modification
  if (props.itemsWithSameId && props.itemsWithSameId.length > 0) {
    propTitle = props.itemsWithSameId[0].content
    clipType = getClipType(props.itemsWithSameId[0].group)
    modification = props.itemsWithSameId[0].ignore ? {mode: 'change', key: 'ignore', newVal: !props.itemsWithSameId[0].ignore} : undefined
    // console.log(props.itemsWithSameId)
    // console.log(modification)
  }

  const titleMaxLength = 40
  let title = propTitle !== undefined ? propTitle !== null ? (propTitle.length > titleMaxLength ? propTitle.substring(0,titleMaxLength).concat('...') : propTitle ) : '' : ''
  // let title = props.title !== (undefined || null) ? (props.title.length > titleMaxLength ? props.title.substring(0,titleMaxLength).concat('...') : props.title ) : ''
  // let contextTrigger = null;

  // const toggleMenu = e => {
  //   if(contextTrigger) {
  //       contextTrigger.handleContextClick(e)
  //   }
  // }

  function handleClick(e, data) {
    // console.log('handleClick:')
    // console.log(data)
    if (typeof data.func === 'function') {
      data.func(data.args)
    }
    if (data.details !== undefined) {
      // console.log(props.tid)
    }
  }
  // console.log(props.clipType)

  return (
    <div id="visContextMenu">
      {/*<ContextMenuTrigger ref={c => contextTrigger = c} id={props.id}>
  </ContextMenuTrigger>*/}
      <ContextMenu id={props.id}>
        <div className="react-contextmenu-headline">
          {title}
        </div>
        <MenuItem data={{details: true}} onClick={handleClick}>
          Details
        </MenuItem>
        {clipType === 'video' && 
        <SubMenu title='Transparez'>
          <MenuItem data={{func: props.changeTransperence, args: {id: props.tid}}} onClick={handleClick}>
            nur diese Auswahl
          </MenuItem>
          <MenuItem data={{func: props.changeTransperence, args: {id: props.tid, sameFile: true}}} onClick={handleClick}>
            alle gleichen Clips
          </MenuItem>
          <MenuItem data={{func: props.changeTransperence, args: {id: props.tid, sameTrack: true}}} onClick={handleClick}>
            alle Clips auf Spur
          </MenuItem>
          <MenuItem data={{func: props.changeTransperence, args: {id: props.tid, sameDir: true}}} onClick={handleClick}>
            alle Dateien im selben Ordner
          </MenuItem>
        </SubMenu>}
        <SubMenu title='Ignorieren'>
          <MenuItem data={{func: props.changeIgnore, args: {id: props.tid, clipType: clipType, modification: modification}}} onClick={handleClick}>
            nur diese Auswahl
          </MenuItem>
          <MenuItem data={{func: props.changeIgnore, args: {id: props.tid, sameFile: true, clipType: clipType, modification: modification}}} onClick={handleClick}>
            alle gleichen Clips
          </MenuItem>
          <MenuItem data={{func: props.changeIgnore, args: {id: props.tid, sameTrack: true, clipType: clipType, modification: modification}}} onClick={handleClick}>
            alle Clips auf Spur
          </MenuItem>
          <MenuItem data={{func: props.changeIgnore, args: {id: props.tid, sameDir: true, clipType: clipType, modification: modification}}} onClick={handleClick}>
            alle Dateien im selben Ordner
          </MenuItem>
        </SubMenu>
        {props.selected.sequenceId && (
          <MenuItem data={{func: props.changeActiveSequence, args: {id: props.selected.sequenceId}}} onClick={handleClick}>
            öffene Sequenz: {props.selected.sequenceId}
          </MenuItem>
        )}
        {props.parentSequence && (
          <MenuItem data={{func: props.changeActiveSequence, args: {id: props.parentSequence.id}}} onClick={handleClick}>
            öffene Sequenz: {props.parentSequence.id}
          </MenuItem>
        )}
        {props.selected.sequenceId && (
          <MenuItem data={{func: props.changeSequenceUnfold, args: {id: props.tid}}} onClick={handleClick}>
            Sequenz aufklappen
          </MenuItem>
        )}
        {props.parentSequence && (
          <MenuItem data={{func: props.changeSequenceUnfold, args: {id: props.parentSequence.clipId}}} onClick={handleClick}>
            Sequenz einklappen
          </MenuItem>
        )}
      </ContextMenu>
    </div>
  )
}

const getClipType = group => {
  if(group.indexOf('a') >= 0) return 'audio'
  if(group.indexOf('v') >= 0) return 'video'
  return undefined
}


export default visContextMenu
