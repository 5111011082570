import React, { Component } from "react"
import { Button, Input, InputGroup, InputGroupAddon, Label } from 'reactstrap'


class TextInput extends Component {

    constructor (props) {
      super(props)
      this.state = {value: props.value, defaultValue: props.value, ids: props.ids, func: props.func, label: props.label}
    }
  
    componentWillReceiveProps (props) {
      this.setState({...this.state, value: props.value, defaultValue: props.value, ids: props.ids})
    }
  
    submitName = (e) => {
      e.preventDefault()
      // console.log(e.target.value)
      this.setState({...this.state, value: e.target.value})
    }
  
    render() {
      return(<InputGroup>
        {this.state.label && (<Label for={this.state.label} sm={2} size='sm'>{this.state.label}</Label>)}
        {/*<Col sm={this.state.label ? 10 : 12}>*/}
      <Input bsSize='sm' value={this.state.value} onChange={evt => this.submitName(evt)} id={this.state.label}/>
      {this.state.value !== this.state.defaultValue && (
      <InputGroupAddon addonType="append"><Button size='sm' onClick={evt => {
        this.state.func({ids: this.state.ids, newValue: this.state.value})
      }} color="primary">save</Button></InputGroupAddon>)}
        {/*</Col>*/}
      </InputGroup>)
    }
  }

export default TextInput