import React from 'react'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, 
  // Button
} from 'reactstrap'
// import { compose, graphql } from 'react-apollo'
// import gql from 'graphql-tag'
// import { logoutUser } from './loggedIn'

// const userQuery = gql`
//   query {
//     userStatus @client {
//       username
//       loggedIn
//     }
//   }
// `

class NavbarGlobal extends React.Component {
  // functions
  setAllTransparence = null
  exportCSV = null
  changeActiveSequence = null
  exportJSON = null

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      sequences: props.sequences,
      sequenceId: props.sequenceId
    }

    this.setAllTransparence = props.setAllTransparence
    this.exportCSV = props.exportCSV
    this.changeActiveSequence = props.changeActiveSequence
    this.exportJSON = props.exportJSON
  }
  // shallowEqual = (objA, objB) => {
  //   if (objA === objB) {
  //     return true
  //   }
  
  //   if (typeof objA !== 'object' || objA === null ||
  //       typeof objB !== 'object' || objB === null) {
  //     return false
  //   }
  
  //   var keysA = Object.keys(objA)
  //   var keysB = Object.keys(objB)
  
  //   if (keysA.length !== keysB.length) {
  //     return false
  //   }
  
  //   // Test for A's keys different from B.
  //   var bHasOwnProperty = hasOwnProperty.bind(objB);
  //   for (var i = 0; i < keysA.length; i++) {
  //     if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
  //       return false
  //     }
  //   }
  
  //   return true
  // }
  
  // shallowCompare = (instance, nextProps, nextState) => {
  //   return (
  //     !this.shallowEqual(instance.props, nextProps) ||
  //     !this.shallowEqual(instance.state, nextState)
  //   );
  // }
  // shouldComponentUpdate = (nextProps, nextState) => {
  //   // console.log('should we?')
  //   // console.log(nextState)
  //   // nextState.setState({
  //   //   sequences: nextProps.sequences,
  //   //   sequenceId: nextProps.sequenceId
  //   // })
  //   nextState = {...nextState, sequences: nextProps.sequences, sequenceId: nextProps.sequenceId}
  //   // console.log(nextState)
  //   let a = this.shallowCompare(this, nextProps, nextState)
  //   console.log(a)
  //   return a
  // }

  static getDerivedStateFromProps = (props, state) => {
    // console.log({...this.state, sequences: props.sequences, sequenceId: props.sequenceId})
    return {...state, sequences: props.sequences, sequenceId: props.sequenceId}
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }
  render() {
    return (
      <div>
        <Navbar color="light" light expand="md">
          <NavbarBrand href="/#">PPro Timeline csv</NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {/*<NavItem>
                <NavLink href='/#' onClick={evt => console.log('lol')}>Start</NavLink>
              </NavItem>*/}
              {(this.state.sequences && this.state.sequences.length !== 0) && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Export
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem tag="a">
                      <div onClick={() => this.exportCSV({ video: true, music: true })}>
                        Listen herunterladen
                      </div>
                    </DropdownItem>
                    <DropdownItem tag="a">
                      <div onClick={() => this.exportCSV({ video: true })}>
                        Video Liste herunterladen
                      </div>
                    </DropdownItem>
                    <DropdownItem tag="a">
                      <div onClick={() => this.exportCSV({ music: true})}>
                        Musik Liste herunterladen
                      </div>
                    </DropdownItem>
                    <DropdownItem tag="a">
                      <div onClick={() => this.exportJSON()}>
                        JSON herunterladen
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
              {(this.state.sequences && this.state.sequences.length !== 0) && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Bearbeiten
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem tag="a">
                      <div onClick={() => this.setAllTransparence(true)}>
                        alles transparent
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
              {(this.state.sequences && this.state.sequences.length !== 0) && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Sequenz wählen
                  </DropdownToggle>
                  <DropdownMenu right>
                    {this.state.sequences.sort( (a,b) => a.id > b.id ).map((s, i) => (
                      <DropdownItem tag="a" active={s.id === this.state.sequenceId} key={'drpdwnSeq-'+i}>
                        <div onClick={() => this.changeActiveSequence({id: s.id})}>
                          {s.name}
                        </div>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

// export default compose(
//     graphql(userQuery, {
//         props: ({data: { userStatus }}) => ({
//             userStatus
//         })
//     })
// )(NavbarGlobal)

export default NavbarGlobal