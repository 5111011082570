import React, { Component } from "react"
// import Sidebar from "./components/Sidebar"
// import Tutorial from './components/Tutorial'
import VideoTimeline from './components/Timeline'
import Timelist from './components/Timelist'
import NavbarGlobal from './components/Navbar'

// import {exportCSVList, getSourceOfFilePath, getTypeOfFilePath, parseFCPxmlToJson, ticksToFrames} from './components/Functions'

import {exportCSVList, parseFCPxmlToJson, getVisiblePartsSorted2, getAudiblePartsSorted, exportJSONFile} from './components/Functions'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faBan, faEye, faEyeSlash, faFileImage, faFileVideo, faFont, faFileAudio } from '@fortawesome/free-solid-svg-icons'

import "./App.css"
import ImportFile from "./components/ImportFile";
import { Container, Row, Col } from "reactstrap";

library.add(faBan, faEye, faEyeSlash, faFileImage, faFileVideo, faFont, faFileAudio)

class App extends Component {
  initialState = {
    sequences: [],
    filePaths: [],
    fileName: false,
    fileError: false,
    showAdvancedOptions: false,
    title: false,
    showTutorial: false,
    selectedClip: false,
    activeSequenceId: null,
    showStart: true
  }

  state = {...this.initialState}

  stateHistory = []

  componentDidUpdate = (prevProps, prevState) => {
    // if (this.stateHistory.length > 0) {
    //   // console.log('obj1:')
    //   // console.log(this.stateHistory[this.stateHistory.length-1])
    //   // console.log('obj2:')
    //   // console.log(prevState)
    //   if (Object.compare(this.stateHistory[this.stateHistory.length-1], prevState)) {
    //     console.log('is not new')
    //   } else {
    //     console.log('is new')
    //     this.stateHistory.push(JSON.parse(JSON.stringify(prevState)))
    //   }
    // } else {
    //   this.stateHistory.push(JSON.parse(JSON.stringify(prevState)))
    // }
  }

  componentDidMount = function() {
    window.addEventListener('beforeunload', (ev) => {
      ev.preventDefault()
      return ev.returnValue = 'Sure?'
    })
  }

  render() {    
    let seqData = {
      visiblePartsSorted: {
        itemGroups: [],
        list: [], 
        visjs: {
          items: [], 
          groups: []
        }
      }
    }
    let visjs = {
      visjs: {
        items: [],
        groups: []
      }
    }
    // console.log(seqData.visiblePartsSorted)
    if (this.state.sequences !== undefined && this.state.activeSequenceId !== null) {
      seqData = this.state.sequences.find(o => o.id === this.state.activeSequenceId)
      // console.log('seq selected')
      if (seqData && seqData.visiblePartsSorted && seqData.visiblePartsSorted.visjs && seqData.visiblePartsSorted.visjs.items){
        visjs = {
          visjs: {
            // items: [...seqData.visiblePartsSorted.visjs.items, ...seqData.audiblePartsSorted.visjs.items],
            items: this.addGroupClassNameToItem([...seqData.visiblePartsSorted.visjs.items, ...seqData.audiblePartsSorted.visjs.items]),
            groups: [...seqData.visiblePartsSorted.visjs.groups, ...seqData.audiblePartsSorted.visjs.groups.map((g,i) =>(
              {...g, order: seqData.visiblePartsSorted.visjs.groups.length + (seqData.audiblePartsSorted.visjs.groups.length - 1 ) - g.order}
            ))]
          }
        }
        // console.log(visjs)
      }
      
    }

    return (
      <div className="App">
        <Container fluid={true}>
          <Row>
            <Col>
              <NavbarGlobal 
                setAllTransparence={this.setAllTransparence}
                exportCSV={this.exportCSVFileHandler}
                sequenceId={this.state.activeSequenceId}
                changeActiveSequence={this.changeActiveSequence}
                sequences={this.state.sequences}
                exportJSON={this.exportJSONhandler}/>
            </Col>
          </Row>
          {this.state.showStart && 
            <Row>
            <Col sm="12" md={{ size: 6, offset: 3 }}>  
              <ImportFile
                loadFile={this.loadFile}
                sequences={this.state.sequences}
                fileName={this.state.fileName}
                fileError={this.state.fileError}
                showAdvancedOptions={this.state.showAdvancedOptions}
                setShowAdvancedOptions={this.setShowAdvancedOptions}
                title={this.state.title}
                toggleTutorial={this.toggleTutorial}
                getVisibilities={this.getVisibilities}
                exportCSV={this.exportCSVFileHandler}
                sequenceId={this.state.activeSequenceId}
                changeActiveSequence={this.changeActiveSequence}
                setAllTransparence={this.setAllTransparence}
                />
              </Col>
            </Row>}
          {!this.state.showStart &&
            <Row>
              <Col>
                <VideoTimeline
                  changeTransperence={this.changeTransperence}
                  changeRange={this.changeRange}
                  changeIgnore={this.changeIgnore2}
                  selectClip={this.selectClip}
                  sequence={this.state.sequence}
                  changeActiveSequence={this.changeActiveSequence}
                  // partsSorted={seqData.visiblePartsSorted}
                  partsSorted={visjs}
                  seqInfo={seqData.sequence}
                  visRange={seqData.visRange}
                  changeSequenceUnfold={this.changeSequenceUnfold}
                  changeName={this.changeName}
                  changeRights={this.changeRights}
                  createItemGroup={this.createItemGroup}
                  itemGroups={seqData.itemGroups}
                  clipType={'video'}
                  filePaths={this.state.filePaths}
                  changeSource={this.changeSource}
                  changeVisibleInGroup={this.changeVisibleInGroup}/>
              </Col>
            </Row>
          }
          {!this.state.showStart &&
            <Row>
              <Col>
                {(!this.state.showTutorial && 
                  seqData !== undefined && 
                  seqData.visiblePartsSorted !== undefined && 
                  seqData.visiblePartsSorted.list !== undefined) ? 
                    <Timelist
                      changeTransperence={this.changeTransperence}
                      changeRange={this.changeRange}
                      changeIgnore={this.changeIgnore2}
                      selectClip={this.selectClip}
                      list={seqData.visiblePartsSorted.list}
                      changeName={this.changeName}
                      changeRights={this.changeRights}
                      filePaths={this.state.filePaths}
                      changeSequenceUnfold={this.changeSequenceUnfold}
                      clipType={'video'}
                      changeSource={this.changeSource}/> : '' }
                {(!this.state.showTutorial && 
                  seqData !== undefined && 
                  seqData.audiblePartsSorted !== undefined && 
                  seqData.audiblePartsSorted.list !== undefined) ? 
                    <Timelist
                      changeTransperence={this.changeTransperence}
                      changeRange={this.changeRange}
                      changeIgnore={this.changeIgnore2}
                      selectClip={this.selectClip}
                      list={seqData.audiblePartsSorted.list}
                      changeName={this.changeName}
                      changeRights={this.changeRights}
                      filePaths={this.state.filePaths}
                      changeSequenceUnfold={this.changeSequenceUnfold}
                      clipType={'audio'}
                      changeSource={this.changeSource}/> : ''}
              </Col>
            </Row>
          }

          
        </Container>
        
        
        {/*this.state.showStart && <Sidebar
          loadFile={this.loadFile}
          sequences={this.state.sequences}
          fileName={this.state.fileName}
          fileError={this.state.fileError}
          showAdvancedOptions={this.state.showAdvancedOptions}
          setShowAdvancedOptions={this.setShowAdvancedOptions}
          title={this.state.title}
          toggleTutorial={this.toggleTutorial}
          getVisibilities={this.getVisibilities}
          exportCSV={this.exportCSVFileHandler}
          sequenceId={this.state.activeSequenceId}
          changeActiveSequence={this.changeActiveSequence}
          setAllTransparence={this.setAllTransparence}
        />*/}
      </div>
    );
  }


  toggleTutorial = () => {
    this.setState({...this.state, showTutorial: !this.state.showTutorial})
  }

  loadFile = files => {
    this.setState({...this.initialState})
    if (!files.length) {
      this.setState({...this.initialState, fileError: 'Beim Datei-Upload lief etwas schief!'});
      return;
    }
    let file = files[0]
    if (file.name.endsWith(".json")) {
      this.setState({...this.initialState, fileError: 'Die hochgeladene Datei ist eine .json Datei!'});
      if (window.File && window.FileReader && window.FileList && window.Blob) {
        let reader = new FileReader();
        reader.onload = () => {
          // here happens the magic
          let res = JSON.parse(reader.result)
          let openSeq = res.activeSequenceId ? res.activeSequenceId : res.sequences[0].id
          this.setState( {...res, activeSequenceId: openSeq, fileName: file.name, showStart: false} )
          this.getVisibilities()
        };
        reader.readAsText(file);
      } else {
        this.setState({...this.initialState, fileError: 'Die file API wird von diesem Browser nicht ausreichend unterstützt!'});
      }
    }
    if (!file.name.endsWith(".xml")) {
      this.setState({...this.initialState, fileError: 'Die hochgeladene Datei ist keine .xml Datei!'});
      return;
    }
    if (window.File && window.FileReader && window.FileList && window.Blob) {
      let reader = new FileReader();
      reader.onload = () => {
        // here happens the magic
        this.setState( {...parseFCPxmlToJson(reader), fileName: file.name, showStart: false} )
        this.getVisibilities()
      };
      reader.readAsText(file);
    } else {
      this.setState({...this.initialState, fileError: 'Die file API wird von diesem Browser nicht ausreichend unterstützt!'});
    }
  }

  getVisibilities = props => {
    let sequenceId = (props !==undefined && props.sequenceId !== undefined) ? props.sequenceId : this.state.activeSequenceId
    let seq = this.state.sequences.find(seq => { return seq.id === sequenceId })
    if (seq !== undefined) {

      if(seq.videotracks !== undefined && seq.videotracks.length > 0 && seq.audiotracks !== undefined && seq.audiotracks.length > 0) {
        let timebase = seq.timebase

        // let vTracks = JSON.parse(JSON.stringify(seq.videotracks))      
        // let vps = getVisiblePartsSorted({vTracks: vTracks, timebase: timebase, filePaths: this.state.filePaths})
        let vps = getVisiblePartsSorted2({sequences: this.state.sequences, activeSequenceId: this.state.activeSequenceId, timebase: timebase, filePaths: this.state.filePaths})

        let aTracks = JSON.parse(JSON.stringify(seq.audiotracks))
        let aps = getAudiblePartsSorted({aTracks: aTracks, timebase: timebase, filePaths: this.state.filePaths})

        this.setStateSequence({
          sequenceId: this.state.activeSequenceId, 
          newData: { 
            ...vps, 
            ...aps
          }
        })
      }
    }
  }

  createItemGroup = ({ids}) => {
    if (ids !== undefined && ids.length > 1) {
      let existingItemGroups = this.state.sequences.find( s => ( s.id === this.state.activeSequenceId ) ).itemGroups
      if (existingItemGroups === undefined) existingItemGroups = []
      let items = ids.map(x => ({id: x, showInList: true}))

      let newItemGroup = {name: 'new group', id: existingItemGroups.length, items: items}
      console.log(newItemGroup)
      
      existingItemGroups.push(newItemGroup)
      this.setStateSequence({
        sequenceId: this.state.activeSequenceId, 
        newData: { itemGroups: existingItemGroups }
      })
    }
  }

  changeVisibleInGroup = ({groupId, itemId, newValue}) => {
    if (groupId !== undefined && itemId !== undefined) {
      let itemGroups = this.state.sequences.find( s => ( s.id === this.state.activeSequenceId ) ).itemGroups
      // let r = itemGroups.find( x => {
      //   if(x.id === groupId) {
      //     return x.items.find(x => (x.id === itemId))
      //   }
      //   return false
      // })
      let r = itemGroups.map( x => {
        if(x.id === groupId) {
          let a = ({...x, items: x.items.map(y => {
            if (y.id === itemId) {
              // if (newValue === 'on')
              console.log(newValue)
              return ({...y, showInList: !y})
              }
              return y
            })
          })
          return a
        }
        return x
      })
      console.log(itemGroups)
      console.log(r)
      // console.log(groupId)
      // console.log(itemId)
      this.setStateSequence({
        sequenceId: this.state.activeSequenceId, 
        newData: { itemGroups: r }
      })
    }
  }

  addGroupClassNameToItem = items => {
    let groupItems = this.state.itemGroups
    if (groupItems) {
      groupItems
        .map(x => x.items)
        .reduce((acc, val) => acc.concat(val), [])
      items.map( x => {
        // if (getPureId(x.id) === )
        if ( groupItems.find(y => (y === this.getPureId(x.id))) ) {
          // add className
          return ({...x, className: x.className+' groupItem'})
        }
        return (x)
      })
    }
    return items
  }

  getPureId = idWithIndex => {
    let index = idWithIndex.lastIndexOf('_')
    if (index === -1) return idWithIndex
    return idWithIndex.substring(index,0)
  }

  changeActiveSequence = ({id}) => {
    // console.log(id)
    this.setState({...this.state, activeSequenceId: id}, 
      this.getVisibilities )
  }

  changeSequenceUnfold = ({id, ids, sameFile = false, sameDir = false}) => {
    if( id !== undefined ) { 
      // console.log(id)
      this.changeForAllWhere2({id: id, key: 'sequenceUnfold', sameFile: sameFile, sameDir: sameDir, modification: {mode: 'toggle'} })
    }
    if (ids !== undefined) {
      for (let i in ids) {
        this.changeForAllWhere2({id: ids[i], key: 'sequenceUnfold', sameFile: sameFile, sameDir: sameDir, modification: {mode: 'toggle'} })
      }
    }
  }

  changeTransperence = ({id, ids, sameFile = false, sameDir = false, sameTrack = false}) => {
    // console.log(id)
    if( id !== undefined ) { 
      this.changeForAllWhere2({id: id, key: 'transparence', sameFile: sameFile, sameDir: sameDir, sameTrack: sameTrack, modification: {mode: 'toggle'} })
    }
    if (ids !== undefined) {
      for (let i in ids) {
        this.changeForAllWhere2({id: ids[i], key: 'transparence', sameFile: sameFile, sameDir: sameDir, sameTrack: sameTrack, modification: {mode: 'toggle'} })
      }
    }
  }

  // changeIgnore = ({id, ids, sameFile = false, sameDir = false}) => {
  //   // console.log(id)
  //   if( id !== undefined ) { 
  //     this.changeForAllWhere({id: id, key: 'ignore', sameFile: sameFile, sameDir: sameDir, modification: {mode: 'toggle'} })
  //   }
  //   if (ids !== undefined) {
  //     for (let i in ids) {
  //       this.changeForAllWhere({id: ids[i], key: 'ignore', sameFile: sameFile, sameDir: sameDir, modification: {mode: 'toggle'} })
  //     }
  //   }
  // }

  changeName = ({ids, sameFile = true, sameDir = false, newValue}) => {
    console.log(ids)
    if (newValue !== ''){
      for (let id in ids) {
        this.changeForAllWhere2({id: ids[id], key: 'name', sameFile: sameFile, sameDir: sameDir, modification: {mode: 'change', key: 'name', newVal: newValue } })
      }
    }
  }

  changeRights = ({ids, sameFile = true, sameDir = false, newValue}) => {
    if (newValue !== ''){
      for (let id in ids) {
        this.changeForAllWhere2({id: ids[id], key: 'rights', sameFile: sameFile, sameDir: sameDir, modification: {mode: 'change', key: 'rights', newVal: newValue } })
      }
    }
  }

  changeSource = ({ids, sameFile = true, sameDir = false, newValue}) => {
    if (newValue !== ''){
      for (let id in ids) {
        this.changeForAllWhere2({id: ids[id], key: 'source', sameFile: sameFile, sameDir: sameDir, modification: {mode: 'change', key: 'source', newVal: newValue } })
      }
    }
  }

  // changeSource = ({ids, sameFile = true, sameDir = false, newValue}) => {
  //   if (newValue !== ''){
  //     for (let id in ids) {
  //       this.changeForAllWhere2({id: ids[id], key: 'rights', sameFile: sameFile, sameDir: sameDir, modification: {mode: 'change', key: 'rights', newVal: newValue } })
  //     }
  //   }
  // }

  changeIgnore2 = ({id, ids, sameFile = false, sameDir = false, sameTrack = false, modification = {mode: 'toggle'}, clipType}) => {
    // console.log(ids)
    // console.log(modification)
    if( id !== undefined ) { 
      this.changeForAllWhere2({id: id, key: 'ignore', sameFile: sameFile, sameDir: sameDir, sameTrack: sameTrack, modification: modification, clipType: clipType })
    }
    if (ids !== undefined) {
      for (let i in ids) {
        this.changeForAllWhere2({id: ids[i], key: 'ignore', sameFile: sameFile, sameDir: sameDir, sameTrack: sameTrack, modification: modification, clipType: clipType })
      }
    }
  }

  changeForAllWhere2 = ({id, key, sameFile = false, sameDir = false, sameTrack = false, modification, clipType}) => {
    let value = false
    let fileId = undefined
    let sequenceId = undefined
    // console.log(sameTrack)

    // find the clip and toggle it
    this.forEachClipWhere2({key: 'id', val: id, clipType: clipType, sameTrack: sameTrack}, (clip) => {
      if (modification.mode === 'toggle') {
        if (clip[key] !== undefined) {
          clip[key] = !clip[key]
        } else {
          clip[key] = true
        }
        value = clip[key]
      }
      if (modification.mode === 'change') {
        clip[modification.key] = modification.newVal
        value = modification.newVal
      }
      
      sequenceId = clip.sequenceId
      fileId = clip.fileId
      return clip
    })

    if (!sameTrack) {

      let paths = this.state.filePaths
      let sameDirs = []

      // console.log(fileId)
      if (sameFile || sameDir) {
        sameDirs = paths.filter(o => (o.id === fileId))
      }

      if (sameDir && sameDirs[0] !== undefined && sameDirs[0].pathurl !== null) {
        let fullPath = sameDirs[0].pathurl
        let fullPathParent = fullPath.split('/')
        fullPathParent.pop()
        fullPathParent = fullPathParent.join('/')

        sameDirs = paths.filter(o => ( o.pathurl !== null ? o.pathurl.includes(fullPathParent) : false ))
      }

      for (let a in sameDirs) {
        this.forEachClipWhere2({key: 'fileId', val: sameDirs[a].id, clipType: clipType}, (clip) => {
          if (modification.mode === 'toggle') {
            clip[key] = value
          }
          if (modification.mode === 'change') {
            clip[modification.key] = value
          }
          // console.log(clip)
          return clip
        })
      }

      // fallback for sequences! They don't have a fileId
      if (sameFile && sequenceId) {
        this.forEachClipWhere2({key: 'sequenceId', val: sequenceId, clipType: clipType}, (clip) => {
          if (modification.mode === 'toggle') {
            clip[key] = value
          }
          if (modification.mode === 'change') {
            clip[modification.key] = modification.newVal
          }
          return clip
        })
      }

    }
  }

  forEachClipWhere2 = ({key,val,clipType,sameTrack = false}, callback) => {
    // let tracks = null
    // console.log(key)
    // console.log(val)
    let data = this.state.sequences.find(seq => {
      return seq.id === this.state.activeSequenceId
    })
    if (clipType === undefined || clipType === 'video'){
      let tracks = JSON.parse(JSON.stringify(data.videotracks))
      let changed = false
      let track
      if(sameTrack) track = this.getTrackOfClip(tracks, {key: key, val: val})
      for (let a in data.videotracks) {
        for (let b in data.videotracks[a].clips) {
          if ((track && track === a) || data.videotracks[a].clips[b][key] === val) {
            // tracks = [...data.videotracks]
            tracks[a].clips[b] = callback(data.videotracks[a].clips[b])
            changed = true
          }
        }
      }
      if (changed) {
        this.setStateSequence({
          sequenceId: this.state.activeSequenceId, 
          newData: { videotracks: tracks }, 
          callback: this.getVisibilities })
      }
    }
    // tracks = null
    if (clipType === undefined || clipType === 'audio'){
      let tracks = JSON.parse(JSON.stringify(data.audiotracks))
      let changed = false
      let track
      if(sameTrack) track = this.getTrackOfClip(tracks, {key: key, val: val})
      for (let a in data.audiotracks) {
        for (let b in data.audiotracks[a].clips) {
          if ((track && track === a) || data.audiotracks[a].clips[b][key] === val) {
            // tracks = [...data.audiotracks]
            tracks[a].clips[b] = callback(data.audiotracks[a].clips[b])
            changed = true
          }
        }
      }
      if (changed) {
        this.setStateSequence({
          sequenceId: this.state.activeSequenceId, 
          newData: { audiotracks: tracks }, 
          callback: this.getVisibilities })
      }
    }
  }

  // changeGroup = ({id, name, }) => {

  // }

  getTrackOfClip = (tracks, clip) => {
    for (let a in tracks) {
      for (let b in tracks[a].clips) {
        if (tracks[a].clips[b][clip.key] === clip.val)
        return a
      }
    }
    return undefined
  }

  setAllIgnore = (value) => {
    this.setForAll({key: 'ignore', value: value})
  }

  setAllTransparence = (value) => {
    this.setForAll({key: 'transparence', value: value})
  }

  setForAll = ({key, value}) => {
    this.forEachClip((clip) => {
      clip[key] = value
      return clip
    })
  }

  // changeForAllWhere = ({id, key, sameFile = false, sameDir = false, modification}) => {
  //   let value = false
  //   let fileId = undefined
  //   let sequenceId = undefined

  //   // find the clip and toggle it
  //   this.forEachClipWhere({key: 'id', val: id}, (clip) => {
  //     if (modification.mode === 'toggle') {
  //       if (clip[key] !== undefined) {
  //         clip[key] = !clip[key]
  //       } else {
  //         clip[key] = true
  //       }
  //       value = clip[key]
  //     }
  //     if (modification.mode === 'change') {
  //       clip[modification.key] = modification.newVal
  //       value = modification.newVal
  //     }
      
  //     sequenceId = clip.sequenceId
  //     fileId = clip.fileId
  //     return clip
  //   })

  //   let paths = this.state.filePaths
  //   let sameDirs = []

  //   // console.log(fileId)
  //   if (sameFile || sameDir) {
  //     sameDirs = paths.filter(o => (o.id === fileId))
  //   }

  //   if (sameDir && sameDirs[0] !== undefined && sameDirs[0].pathurl !== null) {
  //     let fullPath = sameDirs[0].pathurl
  //     let fullPathParent = fullPath.split('/')
  //     fullPathParent.pop()
  //     fullPathParent = fullPathParent.join('/')

  //     sameDirs = paths.filter(o => ( o.pathurl !== null ? o.pathurl.includes(fullPathParent) : false ))
  //   }

  //   // console.log(sameDirs)
      
  //     // let fullPath = paths.filter(o => (o.id === fileId))[0].pathurl
  //     // // folderName = decodeURIComponent(folderName)
  //     // // console.log(folderName.split('/'))
  //     // let fullPathParent = fullPath.split('/')
  //     // fullPathParent.pop()
  //     // fullPathParent = fullPathParent.join('/')

  //     // let sameDirs = paths.filter(o => ( o.pathurl.includes(fullPathParent) ))
  //     // console.log(sameDirs)

  //   for (let a in sameDirs) {
  //     this.forEachClipWhere({key: 'fileId', val: sameDirs[a].id}, (clip) => {
  //       if (modification.mode === 'toggle') {
  //         clip[key] = value
  //       }
  //       if (modification.mode === 'change') {
  //         clip[modification.key] = value
  //       }
  //       // console.log(clip)
  //       return clip
  //     })
  //   }

  //   // fallback for sequences! They don't have a fileId
  //   if (sameFile && sequenceId) {
  //     this.forEachClipWhere({key: 'sequenceId', val: sequenceId}, (clip) => {
  //       if (modification.mode === 'toggle') {
  //         clip[key] = value
  //       }
  //       if (modification.mode === 'change') {
  //         clip[modification.key] = modification.newVal
  //       }
  //       return clip
  //     })
  //   }
      
  // }

  // forEachClipWhere = ({key,val}, callback) => {
  //   let videotracks = null

  //   let data = this.state.sequences.find(seq => {
  //     return seq.id === this.state.activeSequenceId
  //   })
  //   for (let a in data.videotracks) {
  //     for (let b in data.videotracks[a].clips) {
  //       if (data.videotracks[a].clips[b][key] === val) {
  //         videotracks = [...data.videotracks]
  //         videotracks[a].clips[b] = callback(data.videotracks[a].clips[b])
  //       }
  //     }
  //   }
  //   if (videotracks) {
  //     this.setStateSequence({
  //       sequenceId: this.state.activeSequenceId, 
  //       newData: { videotracks: videotracks }, 
  //       callback: this.getVisibilities })
  //   }
  // }

  forEachClip = (callback) => {
    let videotracks = null

    let data = this.state.sequences.find(seq => {
      return seq.id === this.state.activeSequenceId
    })
    for (let a in data.videotracks) {
      for (let b in data.videotracks[a].clips) {
        videotracks = [...data.videotracks]
        videotracks[a].clips[b] = callback(data.videotracks[a].clips[b])
      }
    }
    if (videotracks) {
      this.setStateSequence({
        sequenceId: this.state.activeSequenceId, 
        newData: { videotracks: videotracks }, 
        callback: this.getVisibilities })
    }
  }

  selectClip = value => {
    this.setState({ selectedClip: value})
  }

  changeRange = value => {
    this.setStateSequence({sequenceId: this.state.activeSequenceId, newData: { visRange: {start: value.start, end: value.end} } })
  }

  setStateSequence = ({sequenceId, newData, callback}) => {
    let otherSeqs = this.state.sequences.filter( s => ( s.id !== sequenceId ) )
    let selectedSeq = this.state.sequences.find( s => ( s.id === sequenceId ) )
    // console.log(newData)
    this.setState(
      { sequences: [...otherSeqs, {...selectedSeq, ...newData } ] }, callback
    )
  }

  exportCSVFileHandler = ({video = false, music = false}) => {
    let data = this.state.sequences.find(seq => {
      return seq.id === this.state.activeSequenceId
    })
    let fileEnding = this.state.fileName.lastIndexOf('.')
    let fileName = this.state.fileName.substring(0,fileEnding) + '_licences'
    if(video) exportCSVList(this.state.filePaths, JSON.parse(JSON.stringify(data.visiblePartsSorted.list)), fileName+'_video')
    if(music) exportCSVList(this.state.filePaths, JSON.parse(JSON.stringify(data.audiblePartsSorted.list)), fileName+'_music')
  }

  exportJSONhandler = () => {
    let fileEnding = this.state.fileName.lastIndexOf('.')
    let fileName = this.state.fileName.substring(0,fileEnding)
    exportJSONFile({activeSequenceId: this.state.activeSequenceId, sequences: this.state.sequences, filePaths: this.state.filePaths}, fileName)
  }
  // doPrint = value => {
  //   this.setState({selectedStatistic: false},()=>{
  //     setTimeout(()=>{window.print()},500)
  //   })
  // }

  

}

Object.compare = function (obj1, obj2) {
	//Loop through properties in object 1
	for (let p in obj1) {
		//Check property exists on both objects
		if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;
 
		switch (typeof (obj1[p])) {
			//Deep compare objects
			case 'object':
				if (!Object.compare(obj1[p], obj2[p])) return false;
				break;
			//Compare function code
			case 'function':
				if (typeof (obj2[p]) === 'undefined' || (p !== 'compare' && obj1[p].toString() !== obj2[p].toString())) return false;
				break;
			//Compare values
			default:
				if (obj1[p] !== obj2[p]) return false;
		}
	}
 
	//Check object 2 for any extra properties
	for (let p in obj2) {
		if (typeof (obj1[p]) == 'undefined') return false;
	}
	return true;
}

export default App;
