import React from "react"
import { Table, Button } from 'reactstrap'
import { getTimecodeMS, getSourceString, getStockInfos, getRightsInfo } from './Functions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextInput from './TextInput'

var changeSequenceUnfold = null // is func

const Timelist = props => {
  if (props.list !== undefined && props.list.length > 0) {
    changeSequenceUnfold = props.changeSequenceUnfold
    return (
      <div id="timelist">
        <Table bordered responsive size="sm">
          <thead>
            <tr>
              <td>act</td>
              <td>Name</td>
              <td>Type</td>
              <td>Quelle</td>
              <td>TC in</td>
              <td>TC out</td>
              <td>DUR</td>
              <td>Rechteumfang</td>
              <td>Stock</td>
            </tr>
          </thead>
          <tbody>
          { props.list.map(obj => {
            // let sourceInfo = getRightsInfo(props.filePaths, obj.file)
            // console.log(obj.parentSequence)
            return (
                <tr className={(obj.ignore ? 'ignore' : '') + (obj.parentSequence ? ' hasParent' : '')} key={obj.key}>
                  <td>
                    {props.clipType === 'video' && <Button outline size="sm" color={obj.transparence ? 'secondary' : 'primary'} onClick={evt => props.changeTransperence({ids: obj.id})}>{obj.transparence ? <FontAwesomeIcon icon="eye" /> : <FontAwesomeIcon icon="eye" />}</Button>}
                    <Button outline size="sm" color={obj.ignore ? 'danger' : 'secondary'} onClick={evt => props.changeIgnore({ids: obj.id, clipType: props.clipType, modification: {mode: 'change', key: 'ignore', newVal: !obj.ignore} })}>{obj.ignore ? <FontAwesomeIcon icon="ban" /> : <FontAwesomeIcon icon="ban" />}</Button>
                  </td>
                  <td>
                    {/*console.log('lets see')}
                    {console.log(obj.name)*/}
                    <TextInput value={obj.name} ids={obj.id} func={props.changeName}/>
                  </td>
                  <td>{getIcon(props.filePaths, obj)}</td>
                  <td>
                    {/*getSourceString(props.filePaths, obj)*/}
                    {<TextInput value={getSourceString(props.filePaths, obj)} ids={obj.id} func={props.changeSource}/>}
                  </td>
                  <td>{getTimecodeMS(obj.start.frames, 25)}</td>
                  <td>{getTimecodeMS(obj.end.frames, 25)}</td>
                  <td>{getTimecodeMS(obj.duration.frames, 25)}</td>
                  <td>
                    {/*console.log(obj.file)*/}
                    {/*<TextInput value={obj.rights !== undefined ? obj.rights : (sourceInfo.rights !== undefined ? sourceInfo.rights : '')} ids={obj.id} func={props.changeRights}/>*/}
                    <TextInput value={getRightsInfo(props.filePaths, obj)} ids={obj.id} func={props.changeRights}/>
                  </td>
                  <td>{getStockInfos(props.filePaths, obj.fileId)}</td>
                </tr>
              )
            })
          }
          </tbody>
        </Table>
      </div>
    )
  } else {
    return null
  }
  
}



var getIcon = (filePaths, obj) => {
  if (obj.sequenceId !== undefined) {
    return (
      <Button outline size="sm" color={obj.sequenceUnfold ? 'secondary' : 'primary'} onClick={evt => {
        // alert(obj.sequenceId)
        console.log(obj)
        changeSequenceUnfold({ids: obj.id})
      }}>
        {obj.sequenceUnfold ? 'seq fold' : 'seq unfold'}
      </Button>)
  }
  // if (data.filePaths != undefined) {
    for(let a in filePaths) {
      if(filePaths[a].id === obj.fileId) {
        switch (filePaths[a].type) {
          case 'video':
            return <FontAwesomeIcon icon="file-video" />
          case 'image':
            return <FontAwesomeIcon icon="file-image" />
          case 'text':
            return (
            <Button outline size="sm" color={'primary'} onClick={evt => alert(obj.textArr)}>
              <FontAwesomeIcon icon="font" />
            </Button>)
          case 'audio':
            return <FontAwesomeIcon icon="file-audio" />
          default:
            return filePaths[a].type
        }
      }
    }
  // }
  // console.log(obj)
  return ""
}

export default Timelist;
