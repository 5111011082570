import React from "react"
import Dropzone from "react-dropzone"

const ImportFile = props => {
  return (
    <div id="ImportFile">
      <div className="sidebar-headline">
        <h1>PPro Timeline</h1>
        <button onClick={ evt => props.toggleTutorial()}>?</button>
      </div>
      <p style={{textAlign: 'center'}} className="dropzone-headline">{props.fileName?<span><b>{props.fileName}</b> geladen</span>: 'Legen Sie ihre FinalCut XML Datei hier ab:'}</p>
      {props.fileError && <p className="dropzone-error">{props.fileError}</p>}
      <div className="dropzone" accept=".xml">
        <Dropzone onDrop={props.loadFile} style={dropStyle}>
          <p style={{textAlign: 'center'}}>
            Legen Sie eine Datei ab, oder klicken Sie zum Hochladen.
          </p>
        </Dropzone>
      </div>
      {(props.sequences.length !== 0) && (
      <div id="sidebar-buttons" style={{display:'grid'}}>
        {props.fileName && (<button onClick={evt => props.getVisibilities()} style={btnPrintStyle}>get visible</button>)}
        {props.fileName && (<button onClick={evt => props.setAllTransparence(true)} style={btnPrintStyle}>set all to transparent</button>)}
        {props.fileName && (<button onClick={evt => props.exportCSV()} style={btnPrintStyle}>get list</button>)}
        {props.fileName && (<select onChange={e => props.changeActiveSequence({id: e.target.value})} style={{display:'block'}} value={props.sequenceId}>
          {props.sequences.sort( (a,b) => a.id > b.id ).map((s, i) => (<option key={'sidebarSequence'+i} value={s.id}>{s.name}</option>))}
          </select>)}
      </div>
      )}
    </div>
  );
};

const dropStyle = {
  width: "100%",
  height: "200px",
  color: "#fff",
  border: "2px dotted #fff",
  borderRadius: "15px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: '#909090'
};

const btnPrintStyle = {
  margin: "1em"
}

export default ImportFile
