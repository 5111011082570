import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, InputGroup } from 'reactstrap';
import TextInput from './TextInput'

class ModalEdit extends React.Component {

  changeName = null
  changeRights = null
  changeSource = null

  constructor(props) {
    super(props);
    this.state = {
      modal: props.isOpen,
      title: props.title,
      pathurl: '',
      selected: props.selected,
      rightsInfo: props.rightsInfo, 
      sourceString: props.sourceString,
      selectedObjs: props.selectedObjs
    };

    this.toggle = this.toggle.bind(this);
    // this.toggleExt = props.toggle.bind(this)

    this.changeName = props.changeName
    this.changeRights = props.changeRights
    this.changeSource = props.changeSource
    this.changeVisibleInGroup = props.changeVisibleInGroup
  }

  componentWillReceiveProps(props){
    //this will get whenever state changes after initial render
    this.setState({...this.state, title: props.title, pathurl: props.pathurl, selected: props.selected, rightsInfo: props.rightsInfo, sourceString: props.sourceString, selectedObjs: props.selectedObjs})
  }

  // toggleExt() {
  //   console.log('tE')
  //   this.setState({
  //     modal: !this.state.modal
  //   });
  // }

  toggle(props) {
    // console.log(props)
    this.setState({...this.state,
      modal: !this.state.modal,
      // title: props.title,
      // pathurl: props.pathurl
    });
  }

  changeNameModal = (props) => {
    // this.setState({...this.state, title: props.newValue})
    // this.setState({...this.state, title2: 'lol'})
    // console.log(this.state)
    this.changeName(props)
    this.toggle()
  }

  recurs(arr, nested) {
    var last = arr.pop()
    if(last === '') return (this.recurs(arr, nested))
    if(arr.length < 1) return (<ul className='foldertree'><li class='root'>{last}</li>{nested}</ul>)
    var n = <li>{last}<ul>{nested}</ul></li>
    return (this.recurs(arr, n))
  }

  render() {
    // let pathComponents = decodeURIComponent(this.state.pathurl).split('/')
    return (
      <div>
        {/*<Button color="danger" onClick={this.toggle}>{this.props.buttonLabel}</Button>*/}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size='lg'>
          <ModalHeader toggle={this.toggle}>{this.state.selected.title}</ModalHeader>
          {/*.this.state.selected.clipObj && <ModalBody>
            <ul>
            {pathComponents.map((o, key) => {
              return(<li key={key} className="filepath">{o}</li>)
            })}
            </ul>
            <TextInput value={this.state.selected.title} ids={[this.state.selected.clipId]} func={this.changeNameModal} label='Name'/>
            <TextInput value={this.state.rightsInfo} ids={[this.state.selected.clipId]} func={this.changeRights} label='Rechte'/>
            <TextInput value={this.state.sourceString} ids={[this.state.selected.clipId]} func={this.changeSource} label='Quelle'/>
          </ModalBody> */}
          {this.state.selectedObjs && <ModalBody>
            {this.state.selectedObjs.map((s,k) => {
              return(
                <div key={k}>
                    {/*decodeURIComponent(s.pathurl).split('/').map((o, key) => {
                      return(<li key={key} className="filepath">{o}</li>)
                    })*/}
                    {this.recurs(decodeURIComponent(s.pathurl).split('/'), [])}
                  <TextInput value={s.title} ids={[s.clipId]} func={this.changeNameModal} label='Name'/>
                  <TextInput value={s.rightsInfo} ids={[s.clipId]} func={this.changeRights} label='Rechte'/>
                  <TextInput value={s.sourceString} ids={[s.clipId]} func={this.changeSource} label='Quelle'/>
                  {s.group &&
                    <InputGroup>
                      <Label sm={2} size='sm'>
                        Sichtbar
                      </Label>
                      <Input className='checkbox-right' type='checkbox' checked={s.showInList} onChange={evt => {
                        this.changeVisibleInGroup({groupId: s.groupId, itemId: s.clipId})
                      }}/>
                    </InputGroup> }
                </div>
              )
            })}
          </ModalBody> }
          <ModalFooter>
          {/*<Button color="primary" onClick={this.toggle}>Do Something</Button>{' '}*/}
            <Button color="secondary" onClick={this.toggle}>Schließen</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalEdit;