import React from "react";
// import { Button } from 'reactstrap'
import Timeline from 'react-visjs-timeline'
import VisContextMenu from './visContextMenu'
import ModalEdit from './ModalEdit'
import { ContextMenuTrigger } from "react-contextmenu"
import KeyboardEventHandler from 'react-keyboard-event-handler'

import '../assets/css/react-contextmenu.css'
import { getSourcePath, getRightsInfo, getSourceString } from "./Functions";

const startTime = 1514761200000
var endTime = startTime + 6*60*1000

const CONTEXTMENUID = 'contextMenuId'

let contextTrigger = null

  const toggleMenu = e => {
    if(contextTrigger && e.item != null) {
        contextTrigger.handleContextClick(e.event)
    }
  }


// var title = null
// var sequenceId = null

// var selectClip = null // is func
// var changeRange = null // is func
// var changeActiveSequence = null // is func
// var selectedClips = []

var showModal = false

class VideoTimeline extends React.Component {

  globalProps = null
  // fn:
  changeActiveSequence = null
  changeIgnore = null
  changeRange = null
  changeSequenceUnfold = null
  changeTransperence = null
  selectClip = null
  changeName = null
  changeRights = null
  changeSource = null

  triggerId = null
  selectedClips = []

  visOptions = {
    width: '100%',
    // height: '400px',
    stack: false,
    showMajorLabels: false,
    showCurrentTime: false,
    min:startTime,
    max:endTime,
    start:startTime,
    end:endTime,
    zoomMax: 86399999,
    zoomMin: 3000,
    editable: false,
    // clickToUse: true,
    format: {
      minorLabels: {
        minute: 'm:ss',
        second: 'm:ss'
      }
    },
    moveable: true,
    zoomKey: 'ctrlKey',
    multiselect: true,
    multiselectPerGroup: true
  }


  constructor(props) {
    super(props)

    // console.log(props)

    this.state = {
      show: false,
      // endTime: 0,
      // visOptions: {},
      // fSelectClip:
      // props: props,
      startTime: 1514761200000,
      endTime: 1514761200000 + 6*60*1000,
      visOptions: this.visOptions,
      filePaths: props.filePaths,
      selected: {
        clipId: null,
        sequenceId: null,
        title: null,
        clipType: null,
        parentSequence: null,
        itemsWithSameId: null,
        pathurl: null,
        clipObj: null
      },
      selectedIds: [],
      selectedObjs: []
    }
    
    this.globalProps = props
  }

  componentWillReceiveProps(props){
    // console.log(props) //this will get whenever state changes after initial render
    this.globalProps = props
    if (
      props.partsSorted !== undefined &&
      props.partsSorted.visjs !== undefined && 
      props.partsSorted.visjs.items.length > 0 && 
      props.partsSorted.visjs.groups.length > 0 
    ){
      let endTime = startTime + (props.seqInfo.timeTotal.frames / props.seqInfo.timebase + 10)*1000
      let visOptions = {...this.state.visOptions, start: startTime, min: startTime, max: endTime, end: endTime }
      if (props.visRange !== undefined && props.visRange.start !== undefined) {
        visOptions = {...visOptions, start: props.visRange.start, end: props.visRange.end }
      }

      // fn:
      this.selectClip = props.selectClip
      this.changeRange = props.changeRange
      this.changeActiveSequence = props.changeActiveSequence
      this.changeIgnore = props.changeIgnore
      this.changeSequenceUnfold = props.changeSequenceUnfold
      this.changeTransperence = props.changeTransperence
      this.changeName = props.changeName
      this.changeRights = props.changeRights
      this.changeSource = props.changeSource
      this.createItemGroup = props.createItemGroup
      this.changeVisibleInGroup = props.changeVisibleInGroup

      let ids = this.state.selectedIds.map(x => ({pureId: this.getPureId(x), itemId: x}))
      let result = [];
      let map = new Map();
      for (let x of ids) {
          if(!map.has(x.pureId)){
              map.set(x.pureId, true);    // set any value to Map
              result.push(x);
          }
      }
      let selectedObjs = result.map(x => x.itemId).map( x => this.getClipItem(x,props.partsSorted.visjs.items))

      // console.log(this.state.itemGroups)
      let clipItemGroups = this.getGroup({id: this.getPureIds(this.state.selectedIds), newItemGroups: props.itemGroups})
      console.log(clipItemGroups)

      // this.state.selected.sequenceId
      this.setState({...this.state,
        show: true,
        visjs: props.partsSorted.visjs,
        filePaths: props.filePaths,
        visOptions: visOptions,
        selected: this.selectedToState(props.partsSorted.visjs.items),
        itemGroups: props.itemGroups,
        selectedObjs: selectedObjs,
        clipItemGroups: clipItemGroups
      })
      // this.setSelectedToState()

      // let sequenceId = null
      // let title = null
      // let parentSequence = null
      // let clipType = undefined
      // let itemsWithSameId = props.partsSorted.visjs.items.filter(obj => {
      //   if (obj.sequenceId !== undefined && this.getPureId(obj.id) === this.triggerId){
      //     sequenceId = obj.sequenceId
      //   }
      //   if (obj.parentSequence && this.getPureId(obj.id) === this.triggerId){
      //     parentSequence = obj.parentSequence
      //   }
      //   let objId = this.getPureId(obj.id)
      //   return objId === this.triggerId
      // })
      // if (itemsWithSameId.length > 0) {
      //   title = itemsWithSameId[0].content
      //   clipType = this.getClipType(itemsWithSameId[0].group)
      // }

      // this.setState({...this.state, 
      //   show: true,
      //   visjs: props.partsSorted.visjs,
      //   selected: {
      //     sequenceId: sequenceId, 
      //     title: title,
      //     clipType: clipType,
      //     parentSequence: parentSequence,
      //     itemsWithSameId: itemsWithSameId
      //   }})

    } else {
      this.setState({...this.state, show: false})
    }
  }

  componentDidUpdate() {
    if(this.refs.timeline !== undefined && this.refs.timeline.$el.getSelection().length === 0){
      this.refs.timeline.$el.setSelection(this.state.selectedIds)
    }
  }

  toggleModal() {
    this.refs.modal.toggle({title: this.state.selected.title})
  }
  
  render() {
    if(this.state.show){
      return (
        <div id="timeline">
          <KeyboardEventHandler
            handleKeys={['i', 't', 'shift+i', 'shift+t', 'return', 'g']}
            onKeyEvent={(key, e) => {
              let selectedIds = []
              if(this.state.selected.clipId == null) {
                selectedIds = this.refs.timeline.$el.getSelection()
              } else {
                selectedIds = this.state.selectedIds
              }
              let selectedIdsPure = this.getPureIds(selectedIds)
              let uniqueSelectedIdsPure = Array.from(new Set(selectedIdsPure));
              
              // console.log(uniqueSelectedIdsPure)
              if(uniqueSelectedIdsPure.length > 0){
                if(key === 'i') {
                  this.changeIgnore({ids: uniqueSelectedIdsPure})
                }
                if(key === 'shift+i') {
                  this.changeIgnore({id: uniqueSelectedIdsPure.slice(-1)[0], sameFile: true})
                }
                if(key === 't') {
                  this.changeTransperence({ids: uniqueSelectedIdsPure})
                }
                if(key === 'shift+t') {
                  this.changeTransperence({id: uniqueSelectedIdsPure.slice(-1)[0], sameFile: true})
                }
                if(key === 'return') {
                  // console.log('return was hit')
                  // showModal = !showModal
                  this.toggleModal()
                }
                if(key === 'g') {
                  // make a group
                  this.createItemGroup({ids: uniqueSelectedIdsPure})
                  // console.log('make a group of:')
                  // console.log(uniqueSelectedIdsPure)
                }
              }
            }} />
          <Timeline ref='timeline' options={this.state.visOptions} items={this.state.visjs.items} groups={this.state.visjs.groups} contextmenuHandler={this.contextMenuHandler} rangechangedHandler={this.rangeChangedHandler} selectHandler={this.selectHandler} />
          <ContextMenuTrigger ref={c => contextTrigger = c} id={CONTEXTMENUID}>
          </ContextMenuTrigger>
          <VisContextMenu 
            tid={this.triggerId} 
            changeTransperence={this.changeTransperence}
            changeIgnore={this.changeIgnore}
            title={this.state.selected.title}
            clipId={this.state.selected.clipId}
            selected={this.state.selected}
            parentSequence={this.state.selected.parentSequence}
            changeActiveSequence={this.changeActiveSequence}
            id={CONTEXTMENUID}
            changeSequenceUnfold={this.changeSequenceUnfold}
            clipType={this.state.selected.clipType}
            itemsWithSameId={this.state.selected.itemsWithSameId}
            // clipType={props.clipType}
            // obj={obj}
            />
          <ModalEdit ref="modal" 
            isOpen={showModal} 
            // toggle={this.toggleModal}
            changeName={this.changeName}
            changeRights={this.changeRights}
            changeSource={this.changeSource}
            selected={this.state.selected}
            title={this.state.selected.title}
            pathurl={this.state.selected.pathurl}
            rightsInfo={getRightsInfo(this.state.filePaths, this.state.selected.clipObj)}
            sourceString={getSourceString(this.state.filePaths, this.state.selected.clipObj)}
            selectedObjs={this.getSelectedObjsForModal()}
            clipItemGroups={this.state.clipItemGroups}
            changeVisibleInGroup={this.changeVisibleInGroup}
            />
        </div>
      )
    } else {
      return null
    }
  }
  
  selectHandler = props => {
    document.activeElement.blur()
    if (props.items !== null && props.items.length > 0) {
      this.selectedClips = props.items

      let selectedIds = this.refs.timeline.$el.getSelection()
      
      // select all clips of that group
      let allIds = this.state.visjs.items.map( x => x.id)
      let visIdsOfGroup = []
      // let clipItemGroups = this.getClipsOfGroup(this.getPureIds(selectedIds))
      let clipItemGroups = this.getGroup({id: this.getPureIds(selectedIds)})
      console.log(clipItemGroups)
      clipItemGroups
      .map(x => x.items)
      .reduce((acc, val) => acc.concat(val), [])
      // console.log(clipItemGroups)
      .map(x => x.id)
      // .filter(a => { console.log(a)
      //   allIds.filter( x => x.includes(a))
      // })
      // console.log(res)
      .forEach(a => {
        let n = allIds.filter( x => x.includes(a))
        visIdsOfGroup.push( n )
      })
      // reduce all to one layer
      visIdsOfGroup = visIdsOfGroup.reduce((acc, val) => acc.concat(val), [])
      // console.log(visIdsOfGroup)
      

      // let idsOfGroup = visIdsOfGroup.map( x => this.getClipItem(x,undefined).clipId)
      // .filter((v,i,a)=>a.indexOf(v)==i)
      // console.log(idsOfGroup)

      
      let newSelection = selectedIds.concat(visIdsOfGroup)
      this.refs.timeline.$el.setSelection(newSelection)

      // console.log(selectedIds)
      // console.log(visIdsOfGroup)
      // console.log(newSelection)

      // let selectedIdsPure = this.getPureIds(selectedIds)
      // let uniqueSelectedIdsPure = Array.from(new Set(selectedIdsPure));
      // console.log( uniqueSelectedIdsPure )
      let ids = newSelection.map(x => ({pureId: this.getPureId(x), itemId: x}))
      let result = [];
      let map = new Map();
      for (let x of ids) {
          if(!map.has(x.pureId)){
              map.set(x.pureId, true);    // set any value to Map
              result.push(x);
          }
      }
      let selectedObjs = result.map(x => x.itemId).map( x => this.getClipItem(x,undefined))

      // console.log(selectedObjs)

      this.setState({...this.state, selected: this.selectedToState(), selectedIds: newSelection, clipItemGroups: clipItemGroups, selectedObjs: selectedObjs})

    }
  }

  getSelectedObjsForModal = () => {
    // if (this.state.clipItemGroups && this.state.clipItemGroups.length > 0) {
    //   return this.state.clipItemGroups
    //   .map(x => x.items)
    //   .reduce((acc, val) => acc.concat(val), [])
    //   .map( x => {
    //     let obj = this.state.selectedObjs.find( y => y.clipId === x.id)
    //     return({
    //       clipId: x.id,
    //       title: obj.title,
    //       pathurl: obj.pathurl,
    //       rightsInfo: getRightsInfo(this.state.filePaths, obj.clipObj),
    //       sourceString: getSourceString(this.state.filePaths, obj.clipObj),
    //       showInList: x.showInList,
    //       group: true
    //     })
    //   })
    // }
    if (this.state.clipItemGroups && this.state.clipItemGroups.length > 0) {
      let u = this.state.clipItemGroups
      .map( z => 
        { return(
          z.items.map(x => {
            let obj = this.state.selectedObjs.find( y => y.clipId === x.id)
            if (obj) {
              return({
                clipId: x.id,
                title: obj.title,
                pathurl: obj.pathurl,
                rightsInfo: getRightsInfo(this.state.filePaths, obj.clipObj),
                sourceString: getSourceString(this.state.filePaths, obj.clipObj),
                showInList: x.showInList,
                group: true,
                groupId: z.id
              })
            }
            return null
            
            })
          )
        })
        .reduce((acc, val) => acc.concat(val), [])
        .filter( x => x !== undefined)
        // console.log(u)
      return u
    }
    
    return this.state.selectedObjs.map(x => ({
      clipId: x.clipId,
      title: x.title,
      pathurl: x.pathurl,
      rightsInfo: getRightsInfo(this.state.filePaths, x.clipObj),
      sourceString: getSourceString(this.state.filePaths, x.clipObj)
    }))
  }

  // getClipItemByVjsId = (itemId) => {
  //   let clipId = null
  //   let title = null
  //   let parentSequence = null
  //   let clipType = null
  //   let itemsWithSameId = null
  //   let pathurl = null
  //   let clipObj = null
  //   let sequenceId = null

  //   if(itemId){
  //     clipId = this.getPureId(itemId)

  //     clipObj = this.getVjsClipObj({id: itemId, newVisjsItems: undefined})

  //     let visjsItems = this.state.visjs.items

  //     itemsWithSameId = visjsItems.filter(obj => {
  //       if (obj.sequenceId !== undefined && this.getPureId(obj.id) === clipId){
  //         sequenceId = obj.sequenceId
  //       }
  //       if (obj.parentSequence && this.getPureId(obj.id) === clipId){
  //         parentSequence = obj.parentSequence
  //       }
  //       let objId = this.getPureId(obj.id)
  //       return objId === clipId
  //     })
  //     if (itemsWithSameId.length > 0) {
  //       title = itemsWithSameId[0].content
  //       clipType = this.getClipType(itemsWithSameId[0].group)
  //     }
      
  //     // console.log(clipObj)
  //     pathurl = getSourcePath(this.state.filePaths, clipObj)

  //   }
  //   return({
  //     clipId: clipId,
  //     sequenceId: sequenceId,
  //     title: title,
  //     clipType: clipType,
  //     parentSequence: parentSequence,
  //     itemsWithSameId: itemsWithSameId,
  //     pathurl: pathurl,
  //     clipObj: clipObj
  //   })
    
  // }

  selectedToState = (newVisjsItems) => {
    let clipId = null
    let title = null
    let parentSequence = null
    let clipType = null
    let itemsWithSameId = null
    let pathurl = null
    let clipObj = null
    let sequenceId = null

    if(this.selectedClips.length > 0){
      let items = this.selectedClips
      // if(newSelectedClips) items = newSelectedClips

      clipId = this.getPureId(items[0])

      let visjsItems = this.state.visjs.items
      if(newVisjsItems) visjsItems = newVisjsItems

      clipObj = this.getVjsClipObj({id: items[0], newVisjsItems: newVisjsItems})
      if(clipObj === null) return ({
        clipId: null,
        sequenceId: null,
        title: null,
        clipType: null,
        parentSequence: null,
        itemsWithSameId: null,
        pathurl: null,
        clipObj: null
      })

      itemsWithSameId = visjsItems.filter(obj => {
        if (obj.sequenceId !== undefined && this.getPureId(obj.id) === clipId){
          sequenceId = obj.sequenceId
        }
        if (obj.parentSequence && this.getPureId(obj.id) === clipId){
          parentSequence = obj.parentSequence
        }
        let objId = this.getPureId(obj.id)
        return objId === clipId
      })
      if (itemsWithSameId.length > 0) {
        title = itemsWithSameId[0].content
        clipType = this.getClipType(itemsWithSameId[0].group)
      }
      
      // console.log(clipObj)
      pathurl = getSourcePath(this.state.filePaths, clipObj)

    }
    return({
      clipId: clipId,
      sequenceId: sequenceId,
      title: title,
      clipType: clipType,
      parentSequence: parentSequence,
      itemsWithSameId: itemsWithSameId,
      pathurl: pathurl,
      clipObj: clipObj
    })
    
  }

  getClipItem = (itemId, newVisjsItems) => {
    let clipId = null
    let title = null
    let parentSequence = null
    let clipType = null
    let itemsWithSameId = null
    let pathurl = null
    let clipObj = null
    let sequenceId = null

    if(itemId){
      clipId = this.getPureId(itemId)

      let visjsItems = this.state.visjs.items
      if(newVisjsItems) visjsItems = newVisjsItems

      clipObj = this.getVjsClipObj({id: itemId, newVisjsItems: newVisjsItems})
      if(clipObj === null) return ({
        clipId: null,
        sequenceId: null,
        title: null,
        clipType: null,
        parentSequence: null,
        itemsWithSameId: null,
        pathurl: null,
        clipObj: null
      })

      itemsWithSameId = visjsItems.filter(obj => {
        if (obj.sequenceId !== undefined && this.getPureId(obj.id) === clipId){
          sequenceId = obj.sequenceId
        }
        if (obj.parentSequence && this.getPureId(obj.id) === clipId){
          parentSequence = obj.parentSequence
        }
        let objId = this.getPureId(obj.id)
        return objId === clipId
      })
      if (itemsWithSameId.length > 0) {
        title = itemsWithSameId[0].content
        clipType = this.getClipType(itemsWithSameId[0].group)
      }
      
      // console.log(clipObj)
      pathurl = getSourcePath(this.state.filePaths, clipObj)

    }
    return({
      clipId: clipId,
      sequenceId: sequenceId,
      title: title,
      clipType: clipType,
      parentSequence: parentSequence,
      itemsWithSameId: itemsWithSameId,
      pathurl: pathurl,
      clipObj: clipObj
    })
    
  }

  getVjsClipObj = props => {
    let visjsItems = this.state.visjs.items
    if(props.newVisjsItems) visjsItems = props.newVisjsItems

    if(props.id != null) {
      for(let i in visjsItems) {
        if(props.id === visjsItems[i].id) {
          // console.log (visjsItems[i])
          return visjsItems[i]
        }
      }
    }
    return null
  }
  
  rangeChangedHandler = props => {
    this.changeRange(props)
  }
  
  contextMenuHandler = props => {
    if (props.item !== null) {
  
      this.triggerId = this.getPureId(props.item)
      // console.log(props.item)
      // clipType = props.item.group.lastIndexOf('v') ? 'audio' : 'video'
  
      this.selectClip(this.triggerId)
      props.event.preventDefault()
      toggleMenu(props)
    }
  }
  
  getPureId = idWithIndex => {
    let index = idWithIndex.lastIndexOf('_')
    if (index === -1) return idWithIndex
    return idWithIndex.substring(index,0)
  }
  
  getPureIds = idsWithIndex => {
    let pureIds = []
    for (let i in idsWithIndex) {
      let index = idsWithIndex[i].lastIndexOf('_')
      if (index === -1) pureIds.push(idsWithIndex[i])
      else pureIds.push(idsWithIndex[i].substring(index,0))
    }
    return pureIds
  }
  
  getClipType = group => {
    if(group.indexOf('a') >= 0) return 'audio'
    if(group.indexOf('v') >= 0) return 'video'
    return undefined
  }

  // getIdsOfClipsOfGroup = () => {
  //   // select all clips of that group
  //   let allIds = this.state.visjs.items.map( x => x.id)
  //   let idsOfGroup = []
  //   this.getClipsOfGroup(this.getPureIds(selectedIds))
  //   .map(x => x.id)
  //   .forEach(a => {
  //     let n = allIds.filter( x => x.includes(a))
  //     idsOfGroup.push( n )
  //   })
  //   // reduce all to one layer
  //   idsOfGroup = idsOfGroup.reduce((acc, val) => acc.concat(val), [])
  //   // console.log(idsOfGroup)
  //   return idsOfGroup

  // }

  getClipsOfGroup = id => {
    if (this.state.itemGroups !== undefined) {
      let clipsOfGroup = this.state.itemGroups.filter(x => ( x.items.find( y => (y.id === id[0]) ) ) )
      .map(x => x.items)
      // // reduce all to one layer
      .reduce((acc, val) => acc.concat(val), [])
      // console.log(clipsOfGroup)
      return clipsOfGroup
    }
    return []
  }

  getGroup = ({id, newItemGroups}) => {
    if (newItemGroups !== undefined) {
      return newItemGroups.filter(x => ( x.items.find( y => (y.id === id[0]) ) ) )
    }
    if (this.state.itemGroups !== undefined) {
      return this.state.itemGroups.filter(x => ( x.items.find( y => (y.id === id[0]) ) ) )
    }
    return []
  }
}

export default VideoTimeline
