// Here are all the Functions we need
import xml2json from 'react-xml-parser'

const startTime = 1514761200000
const pproTicks = 254016000000

// var startTime = 0
// var pproTicks = 0

// var request = new XMLHttpRequest()
// request.open('GET', 'https://ppro-csv-dev.drehbankmedia.de/api.php', true)
// request.onload = function () {
//   try {
//     let data = JSON.parse(this.response)
//     if (data === undefined) {
//       alert('Es konnte keine Verbindung hergestellt werden!!!')
//     } else {
//       startTime = data.startTime
//       pproTicks = data.pproTicks
//     }
//   } catch(e) {
//     alert('Es ist ein Fehler aufgetreten: ' + e)
//   }
// }
// request.send()

var convertToCSV = objArray => {
  const delimiter = ';'
    var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line !== '') line += delimiter

            line += array[i][index];
        }

        str += line + '\r\n';
    }

    return str;
}

const exportCSVFile = (headers, items, fileTitle) => {
  if (headers) {
    items.unshift(headers)
  }

  // Convert Object to JSON
  var jsonObject = JSON.stringify(items)

  var csv = convertToCSV(jsonObject)

  var exportedFilename = fileTitle + '.csv' || 'export.csv'

  var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
  if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilename)
  } else {
    var link = document.createElement("a")
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob)
      link.setAttribute("href", url)
      link.setAttribute("download", exportedFilename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

export const exportCSVList = (filePaths, visibleList, fileName) => {
  // header
  var headers = {
      name: 'Name',
      source: "Quelle",
      tcIn: "TC in",
      tcOut: "TC out",
      dur: "DUR",
      new: "Neu gekauft",
      rights: "Rechteumfang"
  }

  // filename
  // let fileEnding = fileNameInit.lastIndexOf('.')
  // var fileName = fileNameInit.substring(0,fileEnding) + '_licences'

  let visibleListFormatted = visibleList
  .filter(obj => {
    return !obj.ignore
  })
  .map(obj => {
    return {
      name: obj.name.replace(/;/g, ','), // remove semicolon to avoid errors of csv
      source: getSourceString(filePaths, obj) ? getSourceString(filePaths, obj) : '',
      tcIn: getTimecodeHMS(obj.start.frames, 25),
      tcOut: getTimecodeHMS(obj.end.frames, 25),
      dur: getTimecodeHMS(obj.duration.frames, 25),
      new: getStockInfos(filePaths, obj.fileId),
      // rights: obj.rights ? obj.rights : (getRightsInfo(filePaths, obj).rights ? getRightsInfo(filePaths, obj).rights : '')
      rights: getRightsInfo(filePaths, obj)
    }
  })

  exportCSVFile(headers, visibleListFormatted, fileName)
}

export const exportJSONFile = (json, fileTitle) => {

  // Convert Object to JSON
  var jsonString = JSON.stringify(json)

  var exportedFilename = fileTitle + '.json' || 'export.json'

  var blob = new Blob([jsonString], { type: 'text;charset=utf-8;' })
  if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilename)
  } else {
    var link = document.createElement("a")
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob)
      link.setAttribute("href", url)
      link.setAttribute("download", exportedFilename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

const getDataOfFile = ({filePaths, clipObj, attributes}) => {
  let returnData = {}
  for(let a in filePaths) {
    if(filePaths[a].id === clipObj.fileId) {
      for(let b in attributes) {
        if(returnData[attributes[b]] === undefined) returnData[attributes[b]] = undefined
        if(filePaths[a][attributes[b]] !== undefined) {
          returnData[attributes[b]] = filePaths[a][attributes[b]]
        }
      }
      // console.log(returnData)
      return returnData
    }
  }

  for(let b in attributes) {
    returnData[attributes[b]] = undefined
  }
  return returnData
}

export const getRightsInfo = (filePaths, obj) => {
  if (obj === null) return null
  if (obj.rights !== undefined) return obj.rights
  let result = getDataOfFile({filePaths: filePaths, clipObj: obj, attributes: ['source']})
  // if ( result !== undefined && result.source !== null ) {
    let {source: {rights = ''} = {rights: ''}} = result
    return rights
  // }
  // return ''

  // return getDataOfFile({filePaths: filePaths, clipObj: obj, attributes: ['source']}).source.rights

  // for(let a in filePaths) {
  //   if(filePaths[a].id === obj.fileId && filePaths[a].source !== null) {
  //     if(filePaths[a].source.rights === undefined) console.log(filePaths[a].source)
  //     return filePaths[a].source.rights
  //   }
  // }
  // return ""
}

export const getSourceString = (filePaths, obj) => {
  if (obj === null) return null
  // console.log(obj) 
  if (obj.source !== undefined) {
    return obj.source}

  // let result = getDataOfFile({filePaths: filePaths, clipObj: obj, attributes: ['source']})
  // // console.log(result)
  // // if ( result !== undefined && result.source !== null ) {
  //   let {source: {owner = ''} = {rights: ''}} = result
  //   return owner

  for(let a in filePaths) {
    if(filePaths[a].id === obj.fileId) {
      if(filePaths[a].type === 'text'){
        return 'Eigenproduktion'
      }
      // console.log(filePaths[a].type)
      let {source: {id, owner} = {} } = filePaths[a]
      return id ? owner + ' ('+id+')' : owner
    }
  }
  return ''
}

export const getSourcePath = (filePaths, obj) => {
  if(obj.fileId !== null && obj.fileId !== undefined){
    for(let a in filePaths) {
      // console.log(''+obj.fileId+' - '+filePaths[a].id )
      if(filePaths[a].id === obj.fileId) {
        // console.log(filePaths[a].pathurl)
        return filePaths[a].pathurl
      }
    }
  }
  return null
}

export const getStockInfos = (filePaths, fileId) => {
  for(let a in filePaths) {
    if(filePaths[a].id === fileId && filePaths[a].source !== undefined && filePaths[a].source.new !== undefined) {
      return filePaths[a].source.new
    }
  }
  return ""
}

export const getTimecodeMS = (frames, timebase) => {
  var timecodeSeconds = 0
  var timecodeMinutes = 0
  if (frames > 0) {
    let timecodeFrames = frames % timebase
    let addSecond = Math.round(timecodeFrames / timebase)
    if (frames - timecodeFrames > 0) {
      var seconds = (frames - timecodeFrames) / timebase + addSecond
      timecodeSeconds = seconds % 60
      if (frames - timecodeFrames - timecodeSeconds*timebase > 0) {
        var minutes = (seconds - timecodeSeconds) / 60
        timecodeMinutes = minutes
      }
    }
  }
  return returnWithLeadingZero(timecodeMinutes) + ":" + returnWithLeadingZero(timecodeSeconds)
}

export const getTimecodeHMS = (frames, timebase) => {
  var timecodeSeconds = 0
  var timecodeMinutes = 0
  if (frames > 0) {
    let timecodeFrames = frames % timebase
    let addSecond = Math.round(timecodeFrames / timebase)
    if (frames - timecodeFrames > 0) {
      var seconds = (frames - timecodeFrames) / timebase + addSecond
      timecodeSeconds = seconds % 60
      if (frames - timecodeFrames - timecodeSeconds*timebase > 0) {
        var minutes = (seconds - timecodeSeconds) / 60
        timecodeMinutes = minutes
      }
    }
  }
  return "00:" + returnWithLeadingZero(timecodeMinutes) + ":" + returnWithLeadingZero(timecodeSeconds)
}

const returnWithLeadingZero = tcInt => {
  if (tcInt === 0) {
    return "00"
  } else if (tcInt < 10) {
    return "0"+tcInt
  }
  return tcInt.toString()
}

export const ticksToFrames = (ticks, fps) => {
  return parseInt( (ticks / pproTicks) * fps , 10 )
}

export const getSourceOfFilePath = clip => {
  // console.log(clip.getElementsByTagName('file')[0].attributes.id)
  // vClip.getElementsByTagName('pathurl')[0] !== undefined ? getSourceOfFilePath(vClip.getElementsByTagName('pathurl')[0].value) : null
  if (clip.getElementsByTagName('mediaSource').length > 0 && 
    clip.getElementsByTagName('mediaSource')[0].value === 'GraphicAndType' ) {
    let textArr = clip.getElementsByTagName('effect')
      // get effects with text; without text returns as undefined
      .map(e => {
        if(e.children.filter(c => (c.name === 'effectid' && c.value === 'GraphicAndType' ) ).length > 0) {
          return e
        }
        return undefined
      })
      .filter(e => (e !== undefined))
      // get the text of the field
      .map(e => e.getElementsByTagName('name')[0].value)
      .filter(e => (e !== ''))
    return { owner: 'Eigendreh', rights: 'Alle Rechte', text: textArr }
  }
  if (clip.getElementsByTagName('mediaSource').length > 0 && 
    clip.getElementsByTagName('mediaSource')[0].value === 'Slug')  {
    return { owner: 'Eigendreh', rights: 'Alle Rechte' }
  }
  if(clip.getElementsByTagName('pathurl')[0] !== undefined) {
    let filePath = clip.getElementsByTagName('pathurl')[0].value
    let filePathDecoded = decodeURIComponent(filePath)
    let indexFilename = filePathDecoded.lastIndexOf("/") + 1
    let fileName = filePathDecoded.substring(indexFilename)

    let source = null
    let listStrings = [
      {str: /SHUTTERSTOCK/, res: {owner: 'shutterstock', rights: 'Standard-Lizenz'}, stock: {0: true, str: /\d+/ }},
      {str: /VIDEOHIVE/, res: {owner: 'videohive', rights: 'Video Media (Multi-Use) Licence'}, stock: {0: true, str: /\d+/ }},
      {str: /ADOBESTOCK/, res: {owner: 'AdobeStock', rights: 'Standard-Lizenz'}, stock: {0: true, str: /\d+/ }},
      {str: /AUDIOJUNGLE/, res: {owner: 'AudioJungle', rights: 'Lizenz ?'}, stock: {0: true, str: /\d+/ }},
      {str: /ANW/, res: {owner: 'AudioNetwork', rights: 'Lizenztyp Professional'}, stock: {0: true, str: /\d+[_,-]\d+/ }}, // ANW1234_12_Ein-Titel.mp3 .match(/[\d,\_,\-]+/)
      {str: /CD_/, res: {owner: 'Bluevalley', rights: 'Lifetime-Musiklizenz'}, stock: {0: true, str: /cd\D*\d{2,}[\D,\s]+\d{2,}/ }}, // cd_04-25_Education.wav .match(/[\d,\_]+/)
      {str: /BLUEVALLEY/, res: {owner: 'Bluevalley', rights: 'Lifetime-Musiklizenz'}, stock: {0: true, str: /cd\D*\d{2,}[\D,\s]+\d{2,}/ }},
      {str: /KUNDE/, res: {owner: 'Kunde', rights: 'Alle Rechte für diesen Zweck'}},
      {str: /EXTERN/, res: {owner: 'extern', rights: 'unbekannt'}},
      {str: /1000 VIDEO RAW/, res: {owner: 'Eigendreh', rights: 'Alle Rechte'}},
      {str: /ADOBE PREMIERE PRO CAPTURED AUDIO/, res: {owner: 'Eigenproduktion', rights: 'Alle Rechte', type: 'layout'}},
      {str: /1200 VERPACKUNG/, res: {owner: 'Kunde', rights: 'Alle Rechte'}},
      {str: /1500 GRAFIK FX/, res: {owner: 'Eigenproduktion', rights: 'Alle Rechte'}},
      {str: /1400 AFTER EFFECTS/, res: {owner: 'Eigenproduktion', rights: 'Alle Rechte'}}
    ]
    // .match(/\.\w{3,}$/)  // file-ending
    for (let a in listStrings) {
      source = getOwnerOfNestedFolder({filePath: filePathDecoded.toUpperCase(), str: listStrings[a].str, res: listStrings[a].res})
      if(source) {
        if(listStrings[a].stock) {
          let id = fileName.match(listStrings[a].stock.str) ? fileName.match(listStrings[a].stock.str)[0] : '?'
          source = {...source, id: id, ...getStockInfo({filePath: filePathDecoded, rightsDef: listStrings[a].res.rights})}
        }
        // console.log(source)
        return source
      }
    }
    return undefined
    

    // switch(true) {
    //   case /SHUTTERSTOCK/.test(fileName.toUpperCase()):
    //     return { owner: 'shutterstock', id: fileName.match(/\d+/)[0], ...getStockInfo({filePath: filePathDecoded, rightsDef: 'Standard-Lizenz'}) }
    //   case /VIDEOHIVE/.test(fileName.toUpperCase()):
    //     return { owner: 'videohive', id: fileName.match(/\d+/)[0], ...getStockInfo({filePath: filePathDecoded, rightsDef: 'Multi-Lizenz'}) }
    //   case /ADOBESTOCK/.test(fileName.toUpperCase()):
    //     return { owner: 'AdobeStock', id: fileName.match(/\d+/)[0], ...getStockInfo({filePath: filePathDecoded, rightsDef: 'Standard-Lizenz'}) }
    //   case /AUDIOJUNGLE/.test(fileName.toUpperCase()):
    //     return { owner: 'Audiojungle', id: fileName.match(/\d+/)[0], ...getStockInfo({filePath: filePathDecoded, rightsDef: 'Lizenz ?'}) }
    //   case /AUDIOJUNGLE/.test(filePathDecoded.toUpperCase()):
    //     // get postiion of 'audiojungle'
    //     let uc = filePathDecoded.toUpperCase()
    //     uc = uc.slice(uc.indexOf('AUDIOJUNGLE'))
    //     return { owner: 'Audiojungle', id: uc.match(/\d+/)[0], ...getStockInfo({filePath: filePathDecoded, rightsDef: 'Lizenz ?'}) }
    //   case /ADOBE PREMIERE PRO CAPTURED AUDIO/.test(filePathDecoded.toUpperCase()):
    //     return { owner: 'Eigenproduktion', rights: 'Alle Rechte', type: 'layout'}
    //   case /1000 VIDEO RAW/.test(filePathDecoded.toUpperCase()):
    //     return { owner: 'Eigendreh', rights: 'Alle Rechte'}
    //   case /1200 VERPACKUNG/.test(filePathDecoded.toUpperCase()):
    //     return { owner: 'Kunde', rights: 'Alle Rechte'}
    //   case /1500 GRAFIK FX/.test(filePathDecoded.toUpperCase()):
    //     return { owner: 'Eigenproduktion', rights: 'Alle Rechte'}
    //   case /1400 AFTER EFFECTS/.test(filePathDecoded.toUpperCase()):
    //     return { owner: 'Eigenproduktion', rights: 'Alle Rechte'}
    //   default:
    //     return undefined
    // }
  } else {
  }
  return undefined
}

const getOwnerOfNestedFolder = ({filePath, str, res}) => {
  let pathComponents = decodeURIComponent(filePath).split('/')
    .reverse()
    .find(e => {
      return str.test(e)
    })
  if (pathComponents && pathComponents.length > 0) return res
  // console.log(pathComponents)
  return null
}

const getStockInfo = ({filePath, rightsDef}) => {
  // console.log(filePath)
  switch(true) {
    case /Footage bereits gekauft/.test(filePath):
    case /bereits gekauft/.test(filePath):
      // console.log('found bought')
      return { new: 'nein', rights: rightsDef }
      // break
    case /Footage neu gekauft/.test(filePath):
    case /neu gekauft/.test(filePath):
      // console.log('found new')
      return { new: 'ja', rights: rightsDef }
      // break
    case /Footage preview/.test(filePath):
    case /preview/.test(filePath):
      // console.log('found preview')
      return { new: 'preview', rights: 'keine' }
      // break
    default:
      // console.log('found nothing')
      // console.log(filePath)
      return { new: '??', rights: rightsDef }
  }
}

export const getTypeOfFilePath = clip => {

  if (clip.getElementsByTagName('mediaSource').length > 0 && 
    (clip.getElementsByTagName('mediaSource')[0].value === 'GraphicAndType' ||
    clip.getElementsByTagName('mediaSource')[0].value === 'Slug') ) {
    // let text = clip.getElementsByTagName('effect').filter(o => (o.children.filter(u => (u.name = 'effectid') )))
    // // .children.getElementsByTagName('name')[0].value
    // console.log(text)
    // text = clip.getElementsByTagName('effect').filter(o => (o.getElementsByTagName('effectid')[0] = 'GraphicAndType'))
    // // .children.getElementsByTagName('name')[0].value
    // console.log(text)
    return 'text'
  }
  if (clip.getElementsByTagName('pathurl')[0] !== undefined) {
    if (clip.getElementsByTagName('sequence')[0] !== undefined) {
      // console.log(clip)
      return 'sequenz'
    }
    let filePath = clip.getElementsByTagName('pathurl')[0].value
    let dot = filePath.lastIndexOf(".")
    let fileType = filePath.substring(dot+1);
    switch(fileType.toLowerCase()) {
      case 'psd':
      case 'jpg':
      case 'png':
      case 'tif':
      case 'jpeg':
      case 'eps':
      case 'ai':
        return 'image'
        // break
      case 'aep':
        return 'aftereffects'
        // break
      case 'mxf':
      case 'mp4':
      case 'mov':
      case 'avi':
        return 'video'
        // break
      case 'wav':
      case 'aiff':
      case 'mp3':
        return 'audio'
      default:
        return null
    }
  }
  return null
}

export const parseFCPxmlToJson = (reader) => {
  try {
    // let newJson = {}
    // console.log(reader.result)
    let data = new xml2json().parseFromString(reader.result)
    // console.log(data.getElementsByTagName('sequence')[0]['children'][4]['children'][0].getElementsByTagName('track')[0].getElementsByTagName('clipitem'))
    // let vTracks = data.getElementsByTagName('sequence')[0]['children'][4]['children'][0].getElementsByTagName('track')

    // console.log(data.getElementsByTagName('sequence')[0]['children'][4]['children'][0].getElementsByTagName('sequence'))
    // let filtered = data['children'][0]['children'][0]['children'].filter(function(item) {
    //   return item.name == 'media'
    // })
    let sequences = []
    let filePaths = []
    data['children'][0].getElementsByTagName('sequence').forEach(seq => {
      if(seq.children.length > 0) {
        // console.log(seq)
        let seqDurationFps = seq['children'].filter((item) => {
          return item.name === 'duration'
        })[0].value
        
    
        let seqTimebase = seq['children'].filter((item) => {
          return item.name === 'rate'
        })[0].getElementsByTagName('timebase')[0].value



        let vTracks = seq['children'].filter((item) => {
          return item.name === 'media'
        })[0]['children'][0]['children'].filter((item) => {
          return item.name === 'track'
        })
        // console.log(vTracks)
        let videotracks = []
        // (1)
        // let fps = parseInt( seq.getElementsByTagName('timebase')[0].value )
        for (var i = vTracks.length-1; i >= 0; i--) {
          // let vClips = vTracks[i].getElementsByTagName('clipitem')
          let vClips = vTracks[i]['children']
          // .filter((item) => {
          //   return item.name === ('clipitem' || 'transitionitem')
          // })
          let videoclips = []
          // (2)
          let lastEnd = 0
          for (let j in vClips) {
            let vClip = vClips[j]
            if (vClip.name === 'clipitem' || vClip.name === 'generatoritem') {
              // console.log("3")
              // console.log(vClip.attributes.id)
              // console.log(vClip.getElementsByTagName('file')[0].attributes.id)
              if (vClip.getElementsByTagName('pathurl')[0] !== undefined || vClip.getElementsByTagName('mediaSource')[0] !== undefined) {
                // if (vClip.getElementsByTagName('file').length > 0 ) {
                // console.log(vClip.getElementsByTagName('pathurl')[0] !== undefined ? vClip.getElementsByTagName('pathurl')[0].value : null)
                // console.log(vClip.getElementsByTagName('pathurl')[0] !== undefined ? getSourceOfFilePath(vClip.getElementsByTagName('pathurl')[0].value) : null)
                filePaths.push({
                  id: vClip.getElementsByTagName('file')[0].attributes.id,
                  pathurl: vClip.getElementsByTagName('pathurl')[0] !== undefined ? vClip.getElementsByTagName('pathurl')[0].value : null,
                  name: vClip.getElementsByTagName('name')[0] !== undefined ? vClip.getElementsByTagName('name')[0].value : null,
                  type: getTypeOfFilePath(vClip),
                  source: getSourceOfFilePath(vClip)
                })
              }

              let itemType
              let additional
              // let paramId = vClip.getElementsByTagName('parameterId')
              // if (paramId[0] !== undefined) {
              //   for (let l in paramId ) {
              //     if (paramId[l].value === 'fillcolor') {
              //       itemType = 'generator'
              //     }
              //   }
              // }
              if (vClip.name === 'generatoritem') {
                itemType = 'generator'
              }

              let gg = vClip.getElementsByTagName('filter').find(f => 
                f.getElementsByTagName('effect').find(e => 
                  e.getElementsByTagName('effectid').find(ei => {
                    return ei.value === 'GraphicAndType'
                  })
                )
              )
              if (gg) {
                itemType = 'graphic'
                // console.log(vClip.attributes.id)
                // console.log(gg)
                
              }

              // console.log(vClip.getElementsByTagName('enabled')[0].value)
              // (3) if clip is enabled ----- !vClip.hasTransparence
              if (vClip.getElementsByTagName('enabled')[0].value === "TRUE") {
                // console.log(vClip)
                let start = 0
                let end = 0
                // // using in and out ticks causes some problems.. (for example for large timecodes) so normal in and out are used
                // // let duration = ticksToFrames( parseInt( vClips[j].getElementsByTagName('pproTicksOut')[0].value ) - parseInt( vClips[j].getElementsByTagName('pproTicksIn')[0].value ), fps )
                // let duration = parseInt( vClips[j].getElementsByTagName('out')[0].value ) - parseInt( vClips[j].getElementsByTagName('in')[0].value )
                let clipIn=0
                let clipOut=0
                // if (vClips[j].getElementsByTagName('out')[0].value !== "0") {
                  clipOut = parseInt( vClips[j].getElementsByTagName('out')[0].value , 10 )
                // } else {
                //   clipOut = ticksToFrames( parseInt( vClips[j].getElementsByTagName('pproTicksOut')[0].value ) )
                //   console.log('use ticks out')
                //   console.log(clipOut)
                // }
                // if (vClips[j].getElementsByTagName('in')[0].value !== "0") {
                  clipIn = parseInt( vClips[j].getElementsByTagName('in')[0].value , 10 )
                // } else {
                //   console.log('use ticks in')
                //   console.log(clipIn)
                //   clipIn = ticksToFrames( parseInt( vClips[j].getElementsByTagName('pproTicksIn')[0].value ) )
                // }
                let duration = clipOut - clipIn
                // console.log("1")
                if (vClip.getElementsByTagName('start')[0].value === "-1") {
                  // get start of transitionitem before; this only has start if it's not crossfading. For crossfading we need to take the lastEnd
                  if (vClips[parseInt(j, 10)-1].getElementsByTagName('start')[0].value !== "0") {
                    // if transitionitem 
                    start = parseInt( vClips[parseInt(j, 10)-1].getElementsByTagName('start')[0].value , 10 )
                    // if element 2 positions before has end -1 the transitionitem before is a crossfading, otherwise it's just a fading
                    let item2before = vClips[parseInt(j, 10)-2]
                    if(item2before !== undefined && item2before.getElementsByTagName('end').length > 0 && item2before.getElementsByTagName('end')[0].value === '-1') {
                      // start = Math.floor(( parseInt( vClips[parseInt(j, 10)-1].getElementsByTagName('end')[0].value , 10 ) - parseInt( vClips[parseInt(j, 10)-1].getElementsByTagName('start')[0].value , 10 ) ) / 2 + parseInt( vClips[parseInt(j, 10)-1].getElementsByTagName('start')[0].value , 10 ))
                      start = ticksToFrames( vClips[parseInt(j, 10)-1].getElementsByTagName('cutPointTicks')[0].value, seqTimebase ) + parseInt( vClips[parseInt(j, 10)-1].getElementsByTagName('start')[0].value , 10 )
                      // console.log(vClips[parseInt(j, 10)-1].getElementsByTagName('name')[0].value)
                      // console.log(ticksToFrames( vClips[parseInt(j, 10)-1].getElementsByTagName('cutPointTicks')[0].value, seqTimebase ) + parseInt( vClips[parseInt(j, 10)-1].getElementsByTagName('start')[0].value , 10 ))
                      // console.log(start)
                    }
                  } else {
                    console.log('lastEnd')
                    console.log(vClip)
                    start = lastEnd
                  }
                  duration = duration - ticksToFrames( parseInt( vClips[parseInt(j, 10)-1].getElementsByTagName('cutPointTicks')[0].value , 10 ), seqTimebase )
                } else {
                  start = parseInt(vClip.getElementsByTagName('start')[0].value, 10)
                }
                // console.log("2")
                if (vClip.getElementsByTagName('end')[0].value === "-1") {
                  if (vClips[parseInt(j, 10)+1].getElementsByTagName('end')[0].value !== "0") {
                    end = parseInt( vClips[parseInt(j, 10)+1].getElementsByTagName('end')[0].value , 10 )
                    // if element 2 positions after has start -1 the transitionitem after is a crossfading
                    if(vClips[parseInt(j, 10)+2] !== undefined && vClips[parseInt(j, 10)+2].getElementsByTagName('start').length > 0 && vClips[parseInt(j, 10)+2].getElementsByTagName('start')[0].value === '-1') {
                      // start = Math.floor(( parseInt( vClips[parseInt(j, 10)-1].getElementsByTagName('end')[0].value , 10 ) - parseInt( vClips[parseInt(j, 10)-1].getElementsByTagName('start')[0].value , 10 ) ) / 2 + parseInt( vClips[parseInt(j, 10)-1].getElementsByTagName('start')[0].value , 10 ))
                      end = ticksToFrames( vClips[parseInt(j, 10)+1].getElementsByTagName('cutPointTicks')[0].value, seqTimebase ) + parseInt( vClips[parseInt(j, 10)+1].getElementsByTagName('start')[0].value , 10 )
                      // console.log(vClips[parseInt(j, 10)].getElementsByTagName('name')[0])
                      // console.log(ticksToFrames( vClips[parseInt(j, 10)-1].getElementsByTagName('cutPointTicks')[0].value, seqTimebase ) + parseInt( vClips[parseInt(j, 10)-1].getElementsByTagName('start')[0].value , 10 ))
                      // console.log(end)
                    }
                    duration = end - start

                  } else {
                    duration = duration - ticksToFrames( vClips[parseInt(j, 10)+1].getElementsByTagName('cutPointTicks')[0].value, seqTimebase )
                    end = start + duration
                  }
                } else {
                  end = parseInt(vClip.getElementsByTagName('end')[0].value, 10)
                }
                // // // console.log("3")
                // // console.log(vClip.getElementsByTagName('file')[0].attributes.id)
                // if (vClip.getElementsByTagName('pathurl')[0] !== undefined || vClip.getElementsByTagName('mediaSource')[0] !== undefined) {
                // // if (vClip.getElementsByTagName('file').length > 0 ) {
                //   // console.log(vClip.getElementsByTagName('pathurl')[0] !== undefined ? vClip.getElementsByTagName('pathurl')[0].value : null)
                //   // console.log(vClip.getElementsByTagName('pathurl')[0] !== undefined ? getSourceOfFilePath(vClip.getElementsByTagName('pathurl')[0].value) : null)
                //   filePaths.push({
                //     id: vClip.getElementsByTagName('file')[0].attributes.id,
                //     pathurl: vClip.getElementsByTagName('pathurl')[0] !== undefined ? vClip.getElementsByTagName('pathurl')[0].value : null,
                //     name: vClip.getElementsByTagName('name')[0] !== undefined ? vClip.getElementsByTagName('name')[0].value : null,
                //     type: getTypeOfFilePath(vClip),
                //     source: getSourceOfFilePath(vClip)
                //   })
                // }
                let fileId = undefined
                // if (vClip.getElementsByTagName('sequence')[0] === undefined && vClip.getElementsByTagName('file')[0] !== undefined) {
                if (vClip.getElementsByTagName('file')[0] !== undefined) {
                  fileId = vClip.getElementsByTagName('file')[0].attributes.id
                  // console.log(fileId)
                }
                let sequenceId = undefined
                if (vClip.getElementsByTagName('sequence')[0] !== undefined) {
                  sequenceId = vClip.getElementsByTagName('sequence')[0].attributes.id
                  // console.log('sequence found')
                }
                let transparence
                let ignore
                if (itemType === 'graphic'){
                  transparence = true
                }
                if (itemType === 'generator'){
                  ignore = true
                }
                videoclips.push({
                  'name': vClip.getElementsByTagName('name')[0].value,
                  'id': vClip.attributes.id,
                  'start': {
                    'frames': start
                  },
                  'end': {
                    'frames': end
                  },
                  'duration': {
                    'frames': duration
                  },
                  'inPoint': {
                    'frames': parseInt(vClip.getElementsByTagName('in')[0].value, 10)
                  },
                  'outPoint': {
                    'frames': parseInt(vClip.getElementsByTagName('out')[0].value, 10)
                  },
                  'visible': [{
                    'start': {
                      'frames': start
                    },
                    'end': {
                      'frames': end
                    },
                    'duration': {
                      'frames': duration
                    }
                  }]
                  ,
                  'fileId': fileId,
                  sequenceId: sequenceId,
                  itemType: itemType,
                  transparence: transparence,
                  ignore: ignore
                  // , 'data': vClip
                })
                lastEnd = end
                // console.log(videoclips)
              }
            }  
          }
          videotracks.push({'name': i+"", 'clips':videoclips})
        }

        let aTracks = seq['children'].filter((item) => {
          return item.name === 'media'
        })[0]['children'][1]['children'].filter((item) => {
          return item.name === 'track'
        })
        // get online first index of multiline like stereo
        .filter(aT => {
          if (aT.attributes.currentExplodedTrackIndex === '0') {
            return true
          }
          return false
        })

        let audiotracks = []
        // why is i already declared ?
        for (i = aTracks.length-1; i >= 0; i--) {
          // let vClips = aTracks[i].getElementsByTagName('clipitem')
          let aClips = aTracks[i]['children']
          // .filter((item) => {
          //   return item.name === ('clipitem' || 'transitionitem')
          // })
          let audioclips = []
          // (2)
          let lastEnd = 0
          for (let j in aClips) {
            let aClip = aClips[j]
            if (aClip.name === 'clipitem') {
              // console.log("3")
              // console.log(aClip.attributes.id)
              // console.log(aClip.getElementsByTagName('file')[0].attributes.id)
              if (aClip.getElementsByTagName('pathurl')[0] !== undefined) {
              // if (aClip.getElementsByTagName('file').length > 0 ) {
                // console.log(aClip.getElementsByTagName('pathurl')[0] !== undefined ? aClip.getElementsByTagName('pathurl')[0].value : null)
                // console.log(aClip.getElementsByTagName('pathurl')[0] !== undefined ? getSourceOfFilePath(aClip.getElementsByTagName('pathurl')[0].value) : null)
                // console.log('push to the limits')
                filePaths.push({
                  id: aClip.getElementsByTagName('file')[0].attributes.id,
                  pathurl: aClip.getElementsByTagName('pathurl')[0] !== undefined ? aClip.getElementsByTagName('pathurl')[0].value : null,
                  name: aClip.getElementsByTagName('name')[0] !== undefined ? aClip.getElementsByTagName('name')[0].value : null,
                  type: getTypeOfFilePath(aClip),
                  source: getSourceOfFilePath(aClip)
                })
              }

              // console.log(aClip.getElementsByTagName('enabled')[0].value)
              // (3) if clip is enabled ----- !aClip.hasTransparence
              if (aClip.getElementsByTagName('enabled')[0].value === "TRUE") {
                // console.log(aClip)
                let start = 0
                let end = 0
                // // using in and out ticks causes some problems.. (for example for large timecodes) so normal in and out are used
                // // let duration = ticksToFrames( parseInt( aClips[j].getElementsByTagName('pproTicksOut')[0].value ) - parseInt( aClips[j].getElementsByTagName('pproTicksIn')[0].value ), fps )
                // let duration = parseInt( aClips[j].getElementsByTagName('out')[0].value ) - parseInt( aClips[j].getElementsByTagName('in')[0].value )
                let clipIn=0
                let clipOut=0
                // if (aClips[j].getElementsByTagName('out')[0].value !== "0") {
                  clipOut = parseInt( aClips[j].getElementsByTagName('out')[0].value , 10 )
                // } else {
                //   clipOut = ticksToFrames( parseInt( aClips[j].getElementsByTagName('pproTicksOut')[0].value ) )
                //   console.log('use ticks out')
                //   console.log(clipOut)
                // }
                // if (aClips[j].getElementsByTagName('in')[0].value !== "0") {
                  clipIn = parseInt( aClips[j].getElementsByTagName('in')[0].value , 10)
                // } else {
                //   console.log('use ticks in')
                //   console.log(clipIn)
                //   clipIn = ticksToFrames( parseInt( aClips[j].getElementsByTagName('pproTicksIn')[0].value ) )
                // }
                let duration = clipOut - clipIn
                // console.log("1")
                if (aClip.getElementsByTagName('start')[0].value === "-1") {
                  // get start of transitionitem before; this only has start if it's not crossfading. For crossfading we need to take the lastEnd
                  if (aClips[parseInt(j, 10)-1].getElementsByTagName('start')[0].value !== "0") {
                    start = parseInt( aClips[parseInt(j, 10)-1].getElementsByTagName('start')[0].value , 10 )
                  } else {
                    start = lastEnd
                  }
                  duration = duration - ticksToFrames( parseInt( aClips[parseInt(j, 10)-1].getElementsByTagName('cutPointTicks')[0].value , 10 ), seqTimebase )
                } else {
                  start = parseInt(aClip.getElementsByTagName('start')[0].value, 10)
                }
                // console.log("2")
                if (aClip.getElementsByTagName('end')[0].value === "-1") {
                  if (aClips[parseInt(j, 10)+1].getElementsByTagName('end')[0].value !== "0") {
                    end = parseInt( aClips[parseInt(j, 10)+1].getElementsByTagName('end')[0].value , 10 )
                    duration = end - start
                  } else {
                    duration = duration - ticksToFrames( aClips[parseInt(j, 10)+1].getElementsByTagName('cutPointTicks')[0].value, seqTimebase )
                    end = start + duration
                  }
                } else {
                  end = parseInt(aClip.getElementsByTagName('end')[0].value, 10)
                }
                // // // console.log("3")
                // // console.log(aClip.getElementsByTagName('file')[0].attributes.id)
                // if (aClip.getElementsByTagName('pathurl')[0] !== undefined || aClip.getElementsByTagName('mediaSource')[0] !== undefined) {
                // // if (aClip.getElementsByTagName('file').length > 0 ) {
                //   // console.log(aClip.getElementsByTagName('pathurl')[0] !== undefined ? aClip.getElementsByTagName('pathurl')[0].value : null)
                //   // console.log(aClip.getElementsByTagName('pathurl')[0] !== undefined ? getSourceOfFilePath(aClip.getElementsByTagName('pathurl')[0].value) : null)
                //   filePaths.push({
                //     id: aClip.getElementsByTagName('file')[0].attributes.id,
                //     pathurl: aClip.getElementsByTagName('pathurl')[0] !== undefined ? aClip.getElementsByTagName('pathurl')[0].value : null,
                //     name: aClip.getElementsByTagName('name')[0] !== undefined ? aClip.getElementsByTagName('name')[0].value : null,
                //     type: getTypeOfFilePath(aClip),
                //     source: getSourceOfFilePath(aClip)
                //   })
                // }
                let fileId = undefined
                // if (aClip.getElementsByTagName('sequence')[0] === undefined && aClip.getElementsByTagName('file')[0] !== undefined) {
                if (aClip.getElementsByTagName('file')[0] !== undefined) {
                  fileId = aClip.getElementsByTagName('file')[0].attributes.id
                  // console.log(fileId)
                }
                let sequenceId = undefined
                if (aClip.getElementsByTagName('sequence')[0] !== undefined) {
                  sequenceId = aClip.getElementsByTagName('sequence')[0].attributes.id
                  // console.log('sequence found')
                }
                audioclips.push({
                  'name': aClip.getElementsByTagName('name')[0].value,
                  'id': aClip.attributes.id,
                  'start': {
                    'frames': start
                  },
                  'end': {
                    'frames': end
                  },
                  'duration': {
                    'frames': duration
                  },
                  'inPoint': {
                    'frames': parseInt(aClip.getElementsByTagName('in')[0].value, 10)
                  },
                  'outPoint': {
                    'frames': parseInt(aClip.getElementsByTagName('out')[0].value, 10)
                  },
                  // 'visible': [{
                  //   'start': {
                  //     'frames': start
                  //   },
                  //   'end': {
                  //     'frames': end
                  //   },
                  //   'duration': {
                  //     'frames': duration
                  //   }
                  // }]
                  // ,
                  'fileId': fileId,
                  sequenceId: sequenceId
                  // , 'data': aClip
                })
                lastEnd = end
                // console.log(audioclips)
              }
            }  
          }
          audiotracks.push({'name': i+"", 'clips':audioclips})
        }

        sequences.push({
          id: seq.attributes.id,
          timeTotal:{frames: seqDurationFps},
          timebase: seqTimebase,
          name: seq.getElementsByTagName('name')[0].value,
          videotracks: videotracks,
          audiotracks: audiotracks
        })



      }
        
    })

    // newJson.sequences = sequences
    // newJson.filePaths = filePaths

    
    // console.log(newJson)
    let result = {
      // data: newJson,
      sequences: sequences,
      filePaths: filePaths,
      fileError: false,
      title: false,
      activeSequenceId: data['children'][0].getElementsByTagName('sequence')[0].attributes.id
      }
    // console.log(result)
    return result
  } catch (err) {
    console.log(err)
    return {...this.initialState, fileError: 'Fehler beim parsen des JSON (siehe https://jsonlint.com/)', err: err}
    // this.setState({...this.initialState, fileError: 'Fehler beim parsen des JSON (siehe https://jsonlint.com/)'})
  }
}

// export const getVisiblePartsSorted = ({vTracks, timebase, filePaths}) => {
//   // (1)
//   // for (var i = vTracks.length-1; i >= 0; i--) {
//   for (var i = 0; i < vTracks.length; i++) {
//     // console.log(i +": " + vTracks[i].numberOfItems)
//     let vClips = vTracks[i].clips
//     // (2)
//     for (let j in vClips) {
//       let vClip = vClips[j]
//       // (3)
//       // if (!vClip.hasTransparence) {
//       if (vClip.visible === undefined) {
//         vClip.visible = []
//         vClip.visible.push({'start': {'frames': vClip.start.frames}, 'end': {'frames': vClip.end.frames}, 'duration': {'frames': vClip.end.frames - vClip.start.frames}})
//       }
//       if (vClip.transparence === undefined || vClip.transparence === false) {
//         let vClipVisibles = vClip.visible
//         // (4)
//         for (let k in vClipVisibles) {
//           let vClipVisible = vClipVisibles[k]

//           if (!(vClipVisible.start.frames === 0 && vClipVisible.end.frames === 0)) {
//             // (5)
//             // for (var l = i-1; l >= 0; l--) {
//             for (var l = i+1; l < vTracks.length; l++) {
//               // console.log(l +": " + vTracks[l].numberOfItems)
//               let vClipsLower = vTracks[l].clips
//               // (6)
//               for (let m in vClipsLower) {
//                 let vClipLower = vClipsLower[m]
//                 if (vClipLower.visible === undefined) {
//                   vClipLower.visible = []
//                   vClipLower.visible.push({'start': {'frames': vClipLower.start.frames}, 'end': {'frames': vClipLower.end.frames}, 'duration': {'frames': vClipLower.end.frames - vClipLower.start.frames}})
//                 }
//                 let vClipVisiblesLower = vClipLower.visible
//                 // (7)
//                 for (let n in vClipVisiblesLower) {
//                   let vClipVisibleLower = vClipVisiblesLower[n]
//                   let vcvl = vClipVisibleLower

//                   // console.log(vClip.name)

//                   if (vcvl.start.frames >= vClipVisible.start.frames) {
//                     if (vcvl.end.frames <= vClipVisible.end.frames) {
//                       // console.log("cut off " + vClipVisible.start.frames+" - "+vClipVisible.end.frames + " *** " + vcvl.start.frames+" - "+vcvl.end.frames + " *** upperClip: " + vClip.name + " lowerClip: " + vClipLower.name)
//                       vcvl.start.frames = 0
//                       vcvl.end.frames = 0
//                       vcvl.duration.frames = 0
//                     } 
//                     else if (vcvl.start.frames < vClipVisible.end.frames) {
//                       if (vcvl.end.frames >= vClipVisible.end.frames) {
//                         // console.log("trimmed start.frames " + vClipVisible.start.frames+" - "+vClipVisible.end.frames + " *** " + vcvl.start.frames+" - "+vcvl.end.frames + " *** upperClip: " + vClip.name + " lowerClip: " + vClipLower.name)
//                         vcvl.start.frames = vClipVisible.end.frames
//                         vcvl.duration.frames = vcvl.end.frames - vcvl.start.frames
//                       }
//                     } 
//                   } 
//                   else if (vcvl.start.frames <= vClipVisible.start.frames) {
//                     if (vcvl.end.frames >= vClipVisible.end.frames) {
//                       // console.log("cut in parts " + vClipVisible.start.frames+" - "+vClipVisible.end.frames + " *** " + vcvl.start.frames+" - "+vcvl.end.frames + " *** upperClip: " + vClip.name + " lowerClip: " + vClipLower.name)
//                       vClipVisiblesLower.push({'start': { 'frames': vClipVisible.end.frames} , 'end': {'frames': vcvl.end.frames}, 'duration': {'frames': vcvl.end.frames - vClipVisible.end.frames}})
//                       vcvl.end.frames = vClipVisible.start.frames
//                       vcvl.duration.frames = vcvl.end.frames - vcvl.start.frames
//                     }
//                     else if (vcvl.end.frames > vClipVisible.start.frames && vcvl.end.frames <= vClipVisible.end.frames) {
//                       // console.log("trimmed end.frames " + vClipVisible.start.frames+" - "+vClipVisible.end.frames + " *** " + vcvl.start.frames+" - "+vcvl.end.frames + " *** upperClip: " + vClip.name + " lowerClip: " + vClipLower.name)
//                       vcvl.end.frames = vClipVisible.start.frames
//                       vcvl.duration.frames = vcvl.end.frames - vcvl.start.frames
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         }
//       } 
//     }
//   }

//   // **** Start creating for visJS
//   let visGroups = vTracks.map( (obj, i) => ({id: i, content: obj.name}) )

//   // take all and put as expected
//   let visItemsExpected = vTracks.map((obj, i) => {
//     return obj.clips.map(o => {
//       let classnames = 'expected'
//       classnames += (o.ignore !== undefined && o.ignore === true) ? ' ignore' : ''
//       return {id: o.id+'_00', content: o.name, start: startTime + (o.start.frames/timebase)*1000, end: startTime + (o.end.frames/timebase)*1000, group: i, className: classnames, sequenceId: o.sequenceId, fileId: o.fileId}
//     })
//   })
//   // reduce all to one layer
//   .reduce((acc, val) => acc.concat(val), [])

//   // lets get the visible Items
//   let visItemsVisible = vTracks.map((obj, i) => {
//     return obj.clips.map(o => {
//       return {id: o.id, content: o.name, start: startTime + (o.start.frames/timebase)*1000, end: startTime + (o.end.frames/timebase)*1000, group: i, visible: o.visible, transparence: o.transparence, ignore: o.ignore, sequenceId: o.sequenceId, fileId: o.fileId}
//     })
//   }).reduce((acc, val) => acc.concat(val), [])
//   .map(obj => {
//     return obj.visible.map((o,i) => {
//       let classnames = ''
//       classnames += obj.transparence ? ' transparence' : ''
//       classnames += obj.ignore ? ' ignore' : ''
//       return { id: obj.id+'_'+i, content: obj.content, start: startTime + (o.start.frames/timebase)*1000, end: startTime + (o.end.frames/timebase)*1000, group: obj.group, duration: {frames: o.duration.frames} , transparence: obj.transparence, sequenceId: obj.sequenceId, className: classnames, fileId: obj.fileId }
//       })
//   })
//   // all to one layer
//   .reduce((acc, val) => acc.concat(val), [])
//   // only visible
//   .filter(obj => (obj.duration.frames > 0) )

//   let visItems = visItemsExpected.concat(visItemsVisible)



//   // ***** Start creating for the list
//   // get all the clips
//   let visiblePartsSorted = vTracks.map(obj => ( obj.clips ) )
//   // reduce all clip arrays of the tracks to one
//   .reduce((acc, val) => acc.concat(val), [])

//   // add for each visible part name, id, file, transparence, ignore, rights
//   .map(obj => ( 
//     obj.visible.map(o => {
//       const { source: {text} = {}} = getDataOfFile({filePaths: filePaths, clipObj: obj, attributes: ['source']})
//       return ({...o, 
//         name: obj.name,
//         id: [obj.id],
//         fileId: obj.fileId,
//         transparence: obj.transparence,
//         ignore: obj.ignore,
//         rights: obj.rights,
//         sequenceId: obj.sequenceId ,
//         textArr: text
//         })}
//       )
//     )
//   )
//   // all parts to one layer
//   .reduce((acc, val) => acc.concat(val), [])
//   // filter only visible ones
//   .filter(obj => ( obj.duration.frames > 0 ) )

//   // concat parts of similar clips which were cut into parts
//   .reduce((acc,val) => {
//     if (acc.length > 0) {
//       // get last element 
//       let lastVal = acc.slice(-1)[0]
//       // if (lastVal.id === val.id && lastVal.end.frames+15 >= val.start.frames) {
//       if (lastVal.fileId === val.fileId && lastVal.end.frames+timebase*0.6 >= val.start.frames) {
//         let start = lastVal.start.frames
//         acc.pop()
//         let newVal = val
//         newVal.start.frames = start
//         newVal.duration.frames = lastVal.duration.frames + val.duration.frames
//         newVal.id = lastVal.id.concat(val.id)
//         acc.push(newVal)
//       } else {
//         // ignore clips visible less than 15 frames
//         if (val.duration.frames >= timebase*0.6){
//           acc.push(val)
//         } else {
//           acc.push({...val, ignore: true})
//         }
//       }
//     } else {
//       // ignore clips visible less than 15 frames
//       if (val.duration.frames >= timebase*0.6){
//         acc.push(val)
//       } else {
//         acc.push({...val, ignore: true})
//       }
//     }
//     return acc
//   }, [])
//   // sort by time - start.frame
//   .sort( (a,b) => ( a.start.frames - b.start.frames ) )
//   // add a key for react
//   .map( (obj, i) => ( {...obj, key: 'listitem'+i} ) )


//   // get the last frame to get the total length of the video
//   let lastFrame = visiblePartsSorted.slice(-1)[0].end.frames

//   // let timebase = this.state.sequences.find(seq => {
//   //   return seq.id === this.state.activeSequenceId
//   // }).timebase


//   return { visiblePartsSorted: {
//       list: visiblePartsSorted, 
//       visjs: {
//         items: visItems, 
//         groups: visGroups
//       }
//     },
//     sequence: {
//       timeTotal: {
//         frames: lastFrame + timebase*2
//       },
//       timebase: timebase
//     }
//   } 
// }

export const getAudiblePartsSorted = ({aTracks, timebase, filePaths}) => {

  const isTypeVideo = (fileId) => {
      for(let a in filePaths) {
        if(filePaths[a].id === fileId) {
          switch (filePaths[a].type) {
            case 'video':
              return true
            default:
              return false
          }
        }
      }
    return false
  }

  // set ignore etc. for all
  // console.log(aTracks)
  let audibleData = aTracks.map(track => {
    return({...track,
    clips: track.clips.map(obj => {
      let ignore
      if (obj.ignore === undefined) {
        // console.log('set ignore')
        ignore = isTypeVideo(obj.fileId)
        let {source: {type} = {}} = getDataOfFile({filePaths: filePaths, clipObj: obj, attributes: ['source']})
        if (type === 'layout') ignore = true
      } else {
        ignore = obj.ignore
      }
      return ({...obj, ignore: ignore })
    })}
  )})
  // console.log(audibleData)

  // **** Start creating for visJS
  let visGroups = audibleData.map( (obj, i) => ({id: 'a'+i, content: 'a'+obj.name, order: i}) )

  // take all and put as expected
  let visItemsExpected = audibleData.map((obj, i) => {
    return obj.clips.map(o => {
      let classnames = 'expected'
      classnames += (o.ignore !== undefined && o.ignore === true) ? ' ignore' : ''
      return {id: o.id+'_00', content: o.name, start: startTime + (o.start.frames/timebase)*1000, end: startTime + (o.end.frames/timebase)*1000, group: 'a'+i, className: classnames, sequenceId: o.sequenceId, ignore: o.ignore }
    })
  })
  // reduce all to one layer
  .reduce((acc, val) => acc.concat(val), [])

  // console.log(visItemsExpected)

  // lets get the visible Items
  let visItemsAudible = audibleData.map((obj, i) => {
    return obj.clips.map(o => {
      let classnames
      classnames += o.ignore ? ' ignore' : ''
      return {id: o.id, content: o.name, start: startTime + (o.start.frames/timebase)*1000, end: startTime + (o.end.frames/timebase)*1000, duration: {frames: o.duration.frames}, group: 'a'+i, visible: o.visible, ignore: o.ignore, sequenceId: o.sequenceId, className: classnames}
    })
  }).reduce((acc, val) => acc.concat(val), [])
  // only audible
  .filter(obj => (obj.duration.frames > 0) )
  // console.log(visItemsAudible)
  let visItems = visItemsExpected.concat(visItemsAudible)



  // ***** Start creating for the list
  // get all the clips
  let audiblePartsSorted = audibleData.map(obj => ( obj.clips ) )
  // reduce all clip arrays of the tracks to one
  .reduce((acc, val) => acc.concat(val), [])

  // add for each visible part name, id, file, transparence, ignore, rights
  .map(obj => {
    // console.log(obj)

    // let ignore
    // if (obj.ignore === undefined) {
    //   // console.log('set ignore')
    //   ignore = isTypeVideo(obj.fileId)
    //   let {source: {type} = {}} = getDataOfFile({filePaths: filePaths, clipObj: obj, attributes: ['source']})
    //   if (type === 'layout') ignore = true
    // } else {
    //   ignore = obj.ignore
    // }

    // console.log(getRightsInfo(filePaths, obj.fileId))
    // override id with array
    // let rights = obj.rights !== undefined ? obj.rights : getRightsInfo(filePaths, obj.fileId).rights
    // console.log(getRightsInfo(filePaths, obj))
    return( 
    // {...obj, id: [obj.id], ignore: ignore, rights: getRightsInfo(filePaths, obj), sequenceId: undefined}
    {...obj, id: [obj.id], rights: getRightsInfo(filePaths, obj), sequenceId: undefined}
  )} )
  // filter only audible ones
  .filter(obj => ( obj.duration.frames > 0 ) )
  // sort by time - start.frame
  .sort( (a,b) => ( a.start.frames - b.start.frames ) )
  // add a key for react
  .map( (obj, i) => ( {...obj, key: 'audioListitem'+i} ) )

  // console.log(audiblePartsSorted)

  // get the last frame to get the total length of the video
  // let lastFrame = audiblePartsSorted.slice(-1)[0].end.frames

  return {
    audiblePartsSorted: {
      list: audiblePartsSorted, 
      visjs: {
        items: visItems, 
        groups: visGroups
      }
    }
  }
}

const getVTracks = ({sequences, activeSequenceId, timebase, range}) => {
  let seq = sequences.find(seq => { return seq.id === activeSequenceId })
  let vTracks = JSON.parse(JSON.stringify(seq.videotracks))

  for (let i in vTracks) {
    let vClips = vTracks[i].clips
    for (let j in vClips) {
      let vClip = vClips[j]
      let { sequenceId, sequenceUnfold } = vClip
      
      if (sequenceUnfold && sequenceId) {
        // console.log('seqUnfold')
        // if ( sequenceId ) {
        // get vTracks of sub-sequence and input it
        let subVTracks = getVTracks({sequences: sequences, activeSequenceId: sequenceId, timebase: timebase, 
        })
        let r = subVTracks.map(obj => ( obj.clips ) )
          // reduce all clip arrays of the tracks to one
          .reduce((acc, val) => acc.concat(val), [])
        
          // add for each visible part name, id, file, transparence, ignore, rights
          .map(obj => ( 
            obj.visible.map(o => {
              return ({
                // ...o, 
                // name: obj.name,
                // id: obj.id,
                // fileId: obj.fileId,
                // transparence: obj.transparence,
                // ignore: obj.ignore,
                // rights: obj.rights,
                // sequenceId: obj.sequenceId
                ...obj,
                ...o
                })}
              )
            )
          )
          // all parts to one layer
          .reduce((acc, val) => acc.concat(val), [])
          // filter only visible ones
          .filter(obj => ( obj.duration.frames > 0 ) )
        
          // concat parts of similar clips which were cut into parts
          .reduce((acc,val) => {
            if (acc.length > 0) {
              // get last element 
              let lastVal = acc.slice(-1)[0]
              // if (lastVal.id === val.id && lastVal.end.frames+15 >= val.start.frames) {
              if (lastVal.fileId === val.fileId && lastVal.end.frames+timebase*0.6 >= val.start.frames) {
                let start = lastVal.start.frames
                acc.pop()
                let newVal = val
                newVal.start.frames = start
                newVal.duration.frames = lastVal.duration.frames + val.duration.frames
                newVal.id = lastVal.id.concat(val.id)
                acc.push(newVal)
              } else {
                // ignore clips visible less than 15 frames
                if (val.duration.frames >= timebase*0.6){
                  acc.push(val)
                } else {
                  acc.push({...val, ignore: true})
                }
              }
            } else {
              // ignore clips visible less than 15 frames
              if (val.duration.frames >= timebase*0.6){
                acc.push(val)
              } else {
                acc.push({...val, ignore: true})
              }
            }
            return acc
          }, [])
          .map(o => {
            // put start and end inside of sub-sequence and ignore stuff outside in and out
            let startFrames = o.start.frames + vClip.start.frames - vClip.inPoint.frames
            let endFrames = o.end.frames + vClip.start.frames - vClip.inPoint.frames
            if (startFrames < vClip.start.frames && endFrames > vClip.start.frames) {
              startFrames = vClip.start.frames}
            if (endFrames > vClip.end.frames && startFrames < vClip.end.frames) {
              endFrames = vClip.end.frames}
            if (endFrames < vClip.start.frames || startFrames > vClip.end.frames) {
              startFrames = 0
              endFrames = 0
            }
            return({
              ...o,
              visible: o.visible.map( c => {
                // also of the visible parts
                let startFrames2 = c.start.frames + vClip.start.frames - vClip.inPoint.frames
                let endFrames2 = c.end.frames + vClip.start.frames - vClip.inPoint.frames
                if (startFrames2 < vClip.start.frames && endFrames2 > vClip.start.frames) {
                  startFrames2 = vClip.start.frames}
                if (endFrames2 > vClip.end.frames && startFrames2 < vClip.end.frames) {
                  endFrames2 = vClip.end.frames}
                if (endFrames2 < vClip.start.frames || startFrames2 > vClip.end.frames) {
                  startFrames2 = 0
                  endFrames2 = 0
                }
                return ( { ...c,
                  start: { frames: startFrames2 },
                  end: { frames: endFrames2 },
                  duration: { frames: endFrames2 - startFrames2 },
                  itemType: c.itemType
              } )} ),
              start: { frames: startFrames },
              end: { frames: endFrames },
              duration: { frames: endFrames - startFrames },
              parentSequence: {id: sequenceId, clipId: vClip.id}
            })
          })
          .filter(o => {
            if (
              o.visible.filter( c => {
                if( (c.start.frames < vClip.end.frames && c.end.frames > vClip.start.frames) || (c.start.frames > vClip.end.frames && c.end.frames > vClip.start.frames) ) {
                  return true
                }
                return false
              })
              .length > 0
            ) return true
            return false
            // get only in range  
            // (o.start.frames < vClip.end.frames && o.end.frames > vClip.start.frames) || (o.start.frames > vClip.end.frames && o.end.frames > vClip.start.frames)
          })
          // if sequence as clip is set to transparent the clips visible in the sub-sequence should be transparent too
          .map(o => {
            if (vClip.transparence) return {...o, transparence: true}
            return o
          })

        // console.log(range)
        // console.log(r)
        // console.log(vClip)
        vClip = {...vClip, ignore: true, transparence: true}
        vClips.push(...r)
        // console.log(vClips)
      }     
    }
  }

  // (1) for each track
  // for (var i = vTracks.length-1; i >= 0; i--) {
    for (var i = 0; i < vTracks.length; i++) {
      // console.log(i +": " + vTracks[i].numberOfItems)
      let vClips = vTracks[i].clips
      // (2) for each clip in the track
      for (let j in vClips) {
        let vClip = vClips[j]
        // (3)
        // if (!vClip.hasTransparence) {
        if (vClip.visible === undefined) {
          vClip.visible = []
          vClip.visible.push({'start': {'frames': vClip.start.frames}, 'end': {'frames': vClip.end.frames}, 'duration': {'frames': vClip.end.frames - vClip.start.frames}})
        }
        if (vClip.transparence === undefined || vClip.transparence === false) {
          let vClipVisibles = vClip.visible
          // (4)
          for (let k in vClipVisibles) {
            let vClipVisible = vClipVisibles[k]
  
            if (!(vClipVisible.start.frames === 0 && vClipVisible.end.frames === 0)) {
              // (5)
              // for (var l = i-1; l >= 0; l--) {
              for (var l = i+1; l < vTracks.length; l++) {
                // console.log(l +": " + vTracks[l].numberOfItems)
                let vClipsLower = vTracks[l].clips
                // (6)
                for (let m in vClipsLower) {
                  let vClipLower = vClipsLower[m]
                  if (vClipLower.visible === undefined) {
                    vClipLower.visible = []
                    vClipLower.visible.push({'start': {'frames': vClipLower.start.frames}, 'end': {'frames': vClipLower.end.frames}, 'duration': {'frames': vClipLower.end.frames - vClipLower.start.frames}})
                  }
                  let vClipVisiblesLower = vClipLower.visible
                  // (7)
                  for (let n in vClipVisiblesLower) {
                    let vClipVisibleLower = vClipVisiblesLower[n]
                    let vcvl = vClipVisibleLower
  
                    // console.log(vClip.name)
  
                    if (vcvl.start.frames >= vClipVisible.start.frames) {
                      if (vcvl.end.frames <= vClipVisible.end.frames) {
                        // console.log("cut off " + vClipVisible.start.frames+" - "+vClipVisible.end.frames + " *** " + vcvl.start.frames+" - "+vcvl.end.frames + " *** upperClip: " + vClip.name + " lowerClip: " + vClipLower.name)
                        vcvl.start.frames = 0
                        vcvl.end.frames = 0
                        vcvl.duration.frames = 0
                      } 
                      else if (vcvl.start.frames < vClipVisible.end.frames) {
                        if (vcvl.end.frames >= vClipVisible.end.frames) {
                          // console.log("trimmed start.frames " + vClipVisible.start.frames+" - "+vClipVisible.end.frames + " *** " + vcvl.start.frames+" - "+vcvl.end.frames + " *** upperClip: " + vClip.name + " lowerClip: " + vClipLower.name)
                          vcvl.start.frames = vClipVisible.end.frames
                          vcvl.duration.frames = vcvl.end.frames - vcvl.start.frames
                        }
                      } 
                    } 
                    else if (vcvl.start.frames <= vClipVisible.start.frames) {
                      if (vcvl.end.frames >= vClipVisible.end.frames) {
                        // console.log("cut in parts " + vClipVisible.start.frames+" - "+vClipVisible.end.frames + " *** " + vcvl.start.frames+" - "+vcvl.end.frames + " *** upperClip: " + vClip.name + " lowerClip: " + vClipLower.name)
                        vClipVisiblesLower.push({'start': { 'frames': vClipVisible.end.frames} , 'end': {'frames': vcvl.end.frames}, 'duration': {'frames': vcvl.end.frames - vClipVisible.end.frames}})
                        vcvl.end.frames = vClipVisible.start.frames
                        vcvl.duration.frames = vcvl.end.frames - vcvl.start.frames
                      }
                      else if (vcvl.end.frames > vClipVisible.start.frames && vcvl.end.frames <= vClipVisible.end.frames) {
                        // console.log("trimmed end.frames " + vClipVisible.start.frames+" - "+vClipVisible.end.frames + " *** " + vcvl.start.frames+" - "+vcvl.end.frames + " *** upperClip: " + vClip.name + " lowerClip: " + vClipLower.name)
                        vcvl.end.frames = vClipVisible.start.frames
                        vcvl.duration.frames = vcvl.end.frames - vcvl.start.frames
                      }
                    }
                  }
                }
              }
            }
          }
        } 
      }
    }
    // console.log(JSON.parse(JSON.stringify(vTracks)))
    // console.log(vTracks)
  return vTracks
}

export const getVisiblePartsSorted2 = ({sequences, activeSequenceId, timebase, filePaths}) => {
  // no checking required anymore

  // input sub-sequences
  let vTracks = getVTracks({sequences: sequences, activeSequenceId: activeSequenceId, timebase: timebase})
  // set ignore etc. for all
  // .map((obj, i) => {
  //   return {...obj, clips: obj.clips.map(o => {
  //     let ignore
  //     // let transparence
  //     // console.log(o)
  //     if (o.ignore === undefined && o.itemType === 'generator') {
  //       ignore = true
  //     // } else if (o.ignore === undefined && o.itemType === 'graphic') {
  //     //   ignore = true
  //     } else if (o.ignore !== undefined) {
  //       ignore = o.ignore
  //     }
  //     // if (o.transparence === undefined && o.itemType === 'graphic') {
  //     //   transparence = true
  //     // } else if (o.transparence !== undefined) {
  //     //   transparence = o.transparence
  //     // }
  //     return {...o, ignore: ignore, 
  //       // transparence: transparence 
  //     }
  //   }) }
  // })
 

  // **** Start creating for visJS
  let visGroups = vTracks.map( (obj, i) => ({id: 'v'+i, content: 'v'+obj.name, order: i}) )

  // take all and put as expected
  let visItemsExpected = vTracks.map((obj, i) => {
    return obj.clips.map((o,j) => {
      if (o.sequenceUnfold) return undefined
      // console.log(o.source)
      let classnames = 'expected'
      // classnames += (o.ignore !== undefined && o.ignore === true) ? ' ignore' : ''
      classnames += o.ignore ? ' ignore' : ''
      classnames += o.sequenceId ? ' isSubSequence' : ''
      classnames += o.parentSequence ? ' isSubSequence' : ''
      return {id: o.id+'_0'+i+j, content: o.name, start: startTime + (o.start.frames/timebase)*1000, end: startTime + (o.end.frames/timebase)*1000, group: 'v'+i, className: classnames, sequenceId: o.sequenceId, parentSequence: o.parentSequence, ignore: o.ignore, fileId: o.fileId, rights: o.rights, source: o.source }
    })
  })
  // reduce all to one layer
  .reduce((acc, val) => acc.concat(val), [])
  .filter(obj => (obj !== undefined) )
  
  // lets get the visible Items
  let visItemsVisible = vTracks.map((obj, i) => {
    return obj.clips.map(o => {
      return {id: o.id, content: o.name, start: startTime + (o.start.frames/timebase)*1000, end: startTime + (o.end.frames/timebase)*1000, group: 'v'+i, visible: o.visible, transparence: o.transparence, ignore: o.ignore, sequenceId: o.sequenceId, parentSequence: o.parentSequence, fileId: o.fileId, rights: o.rights, source: o.source }
    })
  }).reduce((acc, val) => acc.concat(val), [])
  .map((obj,j) => {
    return obj.visible.map((o,i) => {
      if (o.sequenceUnfold) return undefined
      let classnames = ''
      classnames += obj.transparence ? ' transparence' : ''
      classnames += obj.ignore ? ' ignore' : ''
      classnames += obj.sequenceId ? ' isSubSequence' : ''
      classnames += obj.parentSequence ? ' isSubSequence' : ''
      return { id: obj.id+'_1'+j+i, content: obj.content, start: startTime + (o.start.frames/timebase)*1000, end: startTime + (o.end.frames/timebase)*1000, group: obj.group, duration: {frames: o.duration.frames} , transparence: obj.transparence, sequenceId: obj.sequenceId, className: classnames, ignore: obj.ignore, fileId: obj.fileId, rights: obj.rights, source: obj.source }
      })
  })
  // let visItemsVisible = vTracks.map((obj) => {
  //   return obj.clips.map((o,i) => {
  //     return {
  //       id: o.id, 
  //       content: o.name, 
  //       start: startTime + (o.start.frames/timebase)*1000, 
  //       end: startTime + (o.end.frames/timebase)*1000, 
  //       group: 'v'+i, 
  //       visible: o.visible.map((p,j) => {
  //         if (p.sequenceUnfold) return undefined
  //         let classnames = ''
  //         classnames += o.transparence ? ' transparence' : ''
  //         classnames += o.ignore ? ' ignore' : ''
  //         classnames += o.sequenceId ? ' isSubSequence' : ''
  //         classnames += o.parentSequence ? ' isSubSequence' : ''
  //         return { 
  //           id: o.id+'_1'+i+j, 
  //           content: o.content, 
  //           start: startTime + (p.start.frames/timebase)*1000, 
  //           end: startTime + (p.end.frames/timebase)*1000, 
  //           group: o.group, 
  //           duration: {frames: p.duration.frames},
  //           transparence: o.transparence,
  //           sequenceId: o.sequenceId,
  //           className: classnames }
  //         }), 
  //       transparence: o.transparence, 
  //       ignore: o.ignore, 
  //       sequenceId: o.sequenceId}
  //   })
  // })
  // .reduce((acc, val) => acc.concat(val), [])
  // all to one layer
  .reduce((acc, val) => acc.concat(val), [])
  // only visible
  .filter(obj => (obj.duration.frames > 0) )

  let visItems = visItemsExpected.concat(visItemsVisible)
  


  // ***** Start creating for the list
  // get all the clips
  let visiblePartsSorted = vTracks.map(obj => ( obj.clips ) )
  // reduce all clip arrays of the tracks to one
  .reduce((acc, val) => acc.concat(val), [])
  // add for each visible part name, id, file, transparence, ignore, rights
  .map(obj => ( 
    obj.visible.map(o => {
      const { source: {text} = {}} = getDataOfFile({filePaths: filePaths, clipObj: obj, attributes: ['source']})
      return ({...o, 
        name: obj.name,
        id: [obj.id],
        fileId: obj.fileId,
        transparence: obj.transparence,
        ignore: obj.sequenceUnfold ? true : obj.ignore,
        rights: obj.rights,
        source: obj.source,
        sequenceId: obj.sequenceId ,
        textArr: text,
        sequenceUnfold: obj.sequenceUnfold,
        parentSequence: obj.parentSequence
        })}
      )
    )
  )
  
  // all parts to one layer
  .reduce((acc, val) => acc.concat(val), [])
  // filter only visible ones
  .filter(obj => ( obj.duration.frames > 0 ) )

  // concat parts of similar clips which were cut into parts
  .reduce((acc,val) => {
    if (acc.length > 0) {
      // get last element 
      let lastVal = acc.slice(-1)[0]
      // if (lastVal.id === val.id && lastVal.end.frames+15 >= val.start.frames) {
      // if (lastVal.fileId === val.fileId && lastVal.end.frames+timebase*0.6 >= val.start.frames) {
      if (lastVal.fileId === val.fileId && lastVal.end.frames+timebase*0.6 >= val.start.frames && lastVal.start.frames < val.start.frames) {
        // console.log(JSON.parse(JSON.stringify(lastVal)))
        // console.log(JSON.parse(JSON.stringify(val)))
        let start = lastVal.start.frames
        acc.pop()
        let newVal = val
        newVal.start.frames = start
        newVal.duration.frames = lastVal.duration.frames + val.duration.frames
        newVal.id = lastVal.id.concat(val.id)
        acc.push(newVal)
      } else {
        // ignore clips visible less than 15 frames
        if (val.duration.frames >= timebase*0.6){
          acc.push(val)
        } else {
          acc.push({...val, ignore: true})
        }
      }
    } else {
      // ignore clips visible less than 15 frames
      if (val.duration.frames >= timebase*0.6){
        acc.push(val)
      } else {
        acc.push({...val, ignore: true})
      }
    }
    return acc
  }, [])
  // sort by time - start.frame
  .sort( (a,b) => ( a.start.frames - b.start.frames ) )
  // add a key for react
  .map( (obj, i) => ( {...obj, key: 'listitem'+i} ) )

  // get the last frame to get the total length of the video
  let lastFrame = visiblePartsSorted.slice(-1)[0].end.frames
  // console.log(lastFrame)
  // console.log(JSON.parse(JSON.stringify(vTracks)))
  // console.log(vTracks)

  // let timebase = this.state.sequences.find(seq => {
  //   return seq.id === this.state.activeSequenceId
  // }).timebase


  return { visiblePartsSorted: {
      list: visiblePartsSorted, 
      visjs: {
        items: visItems, 
        groups: visGroups
      }
    },
    sequence: {
      timeTotal: {
        frames: lastFrame + timebase*2
      },
      timebase: timebase
    }
  } 
}

export default exportCSVFile